<template>
  <div class="container pt-5 pb-5">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a @click="homeRedirect()" href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Servicios Adicionales</li>
    </ol>

    <h1>Servicios adicionales</h1>
    <hr>
    <p class="lead">Conoce los servicios adicionales que tenemos para ti, complementa tu experiencia en vuelo en globo con los mejores servicios.</p>

    <template v-for="itemService in additionalServices">
      <h2 class="text-primary pb-3">{{ itemService.category_name_es }}</h2>

      <div class="services-container">
        <div v-for="service in itemService.items_services" :key="service.additional_service_id" class="px-2">
          <AServiceCardLg :from-home="true" :item-service="service" :custom-class="'item-service-card-inner'" />
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import AServiceCardLg from "@/views/templates/AServiceCardLg.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'Services',
  components: {AServiceCardLg, VueSlickCarousel},
  data() {
    return {
      additionalServices: [],
      slickOptions: {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024, // Punto de interrupción para dispositivos menores a 768px
            settings: {
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  mounted(){
    this.getAdditionalServices()
  },
  methods: {
    homeRedirect(stepBacks = -1) {
      const lang = localStorage.getItem('language') || 'en';

      this.$router.push({ name: 'home-' + lang });
      //this.$router.push({name: 'es/home'}); // Va a la página anterior en el historial
    },
    getAdditionalServices() {
      this.$http.get(`/manage-services/get-additional-services-by-categories`)
          .then((res) => {
            this.additionalServices = res.data.data
          })
    }
  }
}
</script>

<style scoped>
</style>