<template>
  <div class="container pt-5 pb-5">
    <h1 class="text-success border-bottom pb-5"><i class="bi bi-check-circle"></i> Pago confirmado con éxito</h1>

    <p class="lead">Confirmamos que su pago se <strong>realizó con éxito</strong>. Los detalles de su compra se enviarán vía correo electrónico. Por favor conserve el folio de su pago para cualquier duda o aclaración,</p>
    <p class="lead">Para cualquier duda, cambio o aclaración, favor de conservar sus números de folio</p>
    <p>Folio de reserva:</p>
    <p class="display-5"><strong>{{ salesQuoteId }}</strong></p>
    <p>Folio de pago:</p>
    <p class="display-5"><strong>{{ invoicePaymentId }}</strong></p>
    <hr>
    <button @click="goToHome" class="btn btn-lg btn-dark"><i class="bi bi-arrow-right"></i> Seguir comprando</button>
  </div>
</template>

<script>
export default {
  name: 'SuccessPayment',
  props: ['salesQuoteId', 'invoicePaymentId'],
  methods: {
    goToHome() {
      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'home-' + lang,
      });
    }
  }
}
</script>

<style scoped>
</style>