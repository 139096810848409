<template>
  <div class="container-raiting-inner d-inline-block">
    <!-- Estrellas llenas -->
    <i v-for="i in fullStars" :key="'full-' + i" class="bi bi-star-fill filled item-raiting item-raiting-filled"></i>

    <!-- Estrella media (si aplica) -->
    <i v-if="halfStar" class="bi bi-star-half filled item-raiting item-raiting-half"></i>

    <!-- Estrellas vacías -->
    <i v-for="i in emptyStars" :key="'empty-' + i" class="bi bi-star filled item-raiting"></i>
  </div>
</template>

<script>
export default {
  name: 'RaitingStars',
  props: {
    fill: {
      type: Number,
      required: true
    }
  },
  computed: {
    // Número de estrellas completas
    fullStars() {
      return Math.floor(this.fill);
    },
    // Determina si hay una estrella media
    halfStar() {
      return this.fill % 1 !== 0;
    },
    // Calcula cuántas estrellas vacías faltan para llegar a 5
    emptyStars() {
      return 5 - this.fullStars - (this.halfStar ? 1 : 0);
    }
  }
}
</script>

<style scoped>
/* Añadir estilos si es necesario */
</style>
