<template>
  <div class="">
    <Menu></Menu>
    <router-view class="main-container"/>
    <Footer></Footer>
  </div>
</template>

<script>

import Menu from "@/views/templates/Menu.vue";
import Footer from "@/views/templates/Footer.vue";

export default {
  name: 'App',
  components: {Menu, Footer},
}
</script>
<style>
</style>
