<template>
  <div>
    <VueSlickCarousel ref="slick" v-bind="slickOptions">
      <div v-for="service in services" :key="service.additional_service_id" class="px-2">
        <AServiceCard :item-service="service" :custom-class="'item-service-card'" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import AServiceCard from './AServiceCard.vue';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'ServiceCarousel',
  components: {
    VueSlickCarousel,
    AServiceCard
  },
  props: ['services','slickOptions'],
  data() {
    return {

    }
  }
}
</script>

<style scoped>
/* Ajustes opcionales de estilos */
.slick-slide {
  display: flex;
  justify-content: center;
}
</style>
