<template>
  <div>
    <ul class="list-group list-group-flush">
      <li style="text-align: justify;" v-for="(itemRes, index) in restrictions" class="list-group-item" >
        <p class="p-0 m-0">{{ itemRes }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Restrictions',
  data() {
    return {
      restrictions: [
        'Mujeres embarazadas en cualquier etapa de gestación',
        'Personas con problemas de presión arterial.',
        'Personas con fracturas o lesiones recientes.',
        'Personas con problemas cardiorespiratorios.',
        'Cirugías recientes o problemas de columna y/o rodillas.',
        'Padecimientos crónicos (fobia a las alturas, vértigo, etc).',
        'Personas en estado de ebriedad o intoxicadas no podrán realizar la actividad sin derecho a reembolso.',
        'Queda estrictamente prohibido fumar en la zona de despegue, durante el vuelo y en el aterrizaje.',
        'No podrán volar niños menores de 4 años o estatura menor a 1.20 mts.',
        'Todo menor de edad deberá volar con compañía de un adulto y saber seguir las instrucciones del piloto.'
      ],
    }
  }
}
</script>

<style scoped>
</style>