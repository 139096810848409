<template>
  <div>

    <VueSlickCarousel v-bind="slickOptionsBanners" ref="" class="home-carousel">
      <div v-for="i in 10">
        <div style="position:relative;">
          <a href="#!">
            <img src="https://via.placeholder.com/1920x300.png?text=Imagen" alt="">
          </a>
        </div>
      </div>
    </VueSlickCarousel>

    <div class="container pt-5 pb-5">
      <h1 class="pb-3 border-bottom">{{ $t('flights_section.title_secion') }}</h1>
      <p class="lead">¿Sabías que tenemos diferentes tipos de vuelos para que tengas una mejor experiencia?</p>
      <h2 class="text-primary">Vuelos compartidos</h2>
      <p class="lead">Los vuelos compartidos te permiten compartir el viaje con más pasajeros</p>


      <template v-if="!loadingFlights">

        <div class="row justify-content-lg-center align-items-lg-start">
          <div class="col-lg-4 col-md-12 d-lg-flex justify-content-lg-center align-items-lg-start"
               v-for="itemFlight in flights.shared_flights">
            <div class="card shadow-lg rounded border-0 me-lg-5 mb-5">
                <span class="custom-home-bad-top-left badge rounded-pill"
                      :style="{backgroundColor: itemFlight.highlighted_background_color_badge_flight_es}">
                  {{ itemFlight.highlighted_text_flight_es }}
                </span>
              <img :src="itemFlight.flight_thumbnail.path_image_medium" :alt="itemFlight.flight_name"
                   class="img-fluid rounded-top">
              <div class="card-body">
                <h4 class="card-title text-primary">{{ itemFlight.flight_name }}</h4>
                <div class="highlight-top-text mb-2">
                  <img class="img-fluid" :src="getHighlightLink(itemFlight)" alt="">
                  {{ itemFlight.highlighted_icon_text_flight_es }}
                </div>
                <div class="card-text">
                  <p class="m-0 p-0 fs-6">
                    <span class="text-danger"><s>${{ getBeforePrice(itemFlight) }}</s></span>
                    <span class="text-secondary">Ahorras: ${{ getSavingDifferencePrice(itemFlight) }}</span>
                  </p>
                  <h1 class="fw-bold">
                    ${{ getFrontendPrice(itemFlight.flight_after_discount_price) }} MXN
                  </h1>
                </div>
              </div>
              <div class="card-footer">
                <button @click="addFlightToCart(itemFlight)"
                        class="btn btn-primary btn-md btn-custom-action flex-fill mb-2 mb-md-0 me-md-2 w-100">
                  <i class="bi bi-arrow-right"></i> {{ $t('flights_section.buy_now') }}
                </button>
                <button @click="viewMore(itemFlight)"
                        class="btn btn-light btn-md btn-custom-action flex-fill mb-2 mb-md-0 w-100">
                  <i class="bi bi-arrow-up-right"></i> {{ $t('flights_section.view_more') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-primary">Vuelos privados</h2>
        <p class="lead">Los vuelos privados son vuelos para ti y tus acompañantes únicamente, no compartes el vuelo con otros pasajeros</p>

        <div class="row justify-content-lg-center align-items-lg-start">
          <div class="col-lg-4 col-md-12 d-lg-flex justify-content-lg-center align-items-lg-start"
               v-for="itemFlight in flights.private_flights">
            <div class="card shadow-lg rounded border-0 me-lg-5 mb-5">
                <span class="custom-home-bad-top-left badge rounded-pill"
                      :style="{backgroundColor: itemFlight.highlighted_background_color_badge_flight_es}">
                  {{ itemFlight.highlighted_text_flight_es }}
                </span>
              <img :src="itemFlight.flight_thumbnail.path_image_medium" :alt="itemFlight.flight_name"
                   class="img-fluid rounded-top">
              <div class="card-body">
                <h4 class="card-title text-primary">{{ itemFlight.flight_name }}</h4>
                <div class="highlight-top-text mb-2">
                  <img class="img-fluid" :src="getHighlightLink(itemFlight)" alt="">
                  {{ itemFlight.highlighted_icon_text_flight_es }}
                </div>
                <div class="card-text">
                  <p class="m-0 p-0 fs-6">
                    <span class="text-danger"><s>${{ getBeforePrice(itemFlight) }}</s></span>
                    <span class="text-secondary">Ahorras: ${{ getSavingDifferencePrice(itemFlight) }}</span>
                  </p>
                  <h1 class="fw-bold">
                    ${{ getFrontendPrice(itemFlight.flight_after_discount_price) }} MXN
                  </h1>
                </div>
              </div>
              <div class="card-footer">
                <button @click="addFlightToCart(itemFlight)"
                        class="btn btn-primary btn-md btn-custom-action flex-fill mb-2 mb-md-0 me-md-2 w-100">
                  <i class="bi bi-arrow-right"></i> {{ $t('flights_section.buy_now') }}
                </button>
                <button @click="viewMore(itemFlight)"
                        class="btn btn-light btn-md btn-custom-action flex-fill mb-2 mb-md-0 w-100">
                  <i class="bi bi-arrow-up-right"></i> {{ $t('flights_section.view_more') }}
                </button>
              </div>
            </div>
          </div>
        </div>

      </template>


    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import FlightCard from "@/views/templates/FlightCard.vue";
export default {
  components: {VueSlickCarousel,FlightCard},
  name: 'Flights',
  data() {
    return {
      loadingFlights: true,
      slickOptionsBanners: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        dots: true,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
      },
      slickOptions: {
        infinite: true,
        slidesToShow: 3, // Número de elementos en pantalla grande
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
        responsive: [
          {
            breakpoint: 1024, // Para pantallas menores a 1024px (tableta y móviles)
            settings: {
              slidesToShow: 2, // Número de elementos en pantallas de tableta
              slidesToScroll: 1
            }
          },
          {

            breakpoint: 768, // Para pantallas menores a 768px (móviles)
            settings: {
              centerMode: true,
              slidesToShow: 1, // Número de elementos en móviles
              slidesToScroll: 1
            }
          }
        ]
      },
      flights: {
        shared_flights: [],
        private_flights: [],
      }
    }
  },
  mounted() {
    this.getAllFlights()
  },
  methods: {
    getHighlightLink(itemFlight){
      let urlImage = `${process.env.VUE_APP_API_URL}${itemFlight.highlighted_icon_image_path_flight_es}`

      return urlImage
    },
    getFrontendPrice(mainPrice) {
      return this.$number_format(mainPrice.flight_price, 0, '', ',');
    },
    getFormattedPrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_price, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <small>p/${mainPrice.unit_name}</small>`
    },
    viewMore(flightSliderItem) {
      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'flight-details-' + lang,
        params: {
          slug: flightSliderItem.flight_slug,
          itemFlight: JSON.stringify(flightSliderItem)
        }
      });
    },
    addFlightToCart(itemFlight){
      // get localstorage
      let itemsCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // remove item flight if exists and replace to the new one
      //console.log(JSON.stringify(itemsCart))

      localStorage.setItem('items-cart', JSON.stringify(itemFlight))

      console.log(localStorage.getItem('items-cart'))

      const lang = localStorage.getItem('language') || 'en';
      let checkoutUrl = `${lang}/checkout`
      Swal.fire({
        icon: 'success',
        title: 'Perfecto',
        html: `Hemos agregado <strong>${itemFlight.flight_name}</strong> a su carrito de compras. <a href="/${checkoutUrl}">Pagar ahora.</a>`,
        confirmButtonText: 'Cerrar'
      });
    },
    getBeforePrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_before_discount_price.flight_price, 0, '', ',');

      return priceFormatted
    },
    getSavingDifferencePrice(itemFlight){
      let difference = (parseFloat(itemFlight.flight_before_discount_price.flight_price) - parseFloat(itemFlight.flight_after_discount_price.flight_price)).toFixed(2)
      return this.$number_format(difference, 0, '', ',')
    },
    getAllFlights() {
      this.loadingFlights = true
      Promise.all([this.getSharedFlights(), this.getPrivateFlights()])
          .then((resultsAll) => {
            this.flights.shared_flights = resultsAll[0].data.data
            this.flights.private_flights = resultsAll[1].data.data

            console.log(this.flights.shared_flights)
            console.log(this.flights.private_flights)

            this.loadingFlights = false
          })
          .catch((errorsAll) => {

          })
    },
    getSharedFlights() {
      return new Promise((resolve, reject) => {
        this.$http.get(`/manage-services/flights/get-flights?flight-category-id=1?&show-flight-in-flights-view=1&order-flight-home-view=1`)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })

    },
    getPrivateFlights() {
      return new Promise((resolve, reject) => {
        this.$http.get(`/manage-services/flights/get-flights?flight-category-id=2&show-flight-in-flights-view=1&order-flight-home-view=1`)
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
      })
    }
  }
}
</script>

<style scoped>
</style>