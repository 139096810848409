<template>
  <div>
    <ul class="list-group list-group-flush">
      <li style="text-align: justify;" v-for="(itemFaq, index) in faqs" class="list-group-item" >
        <p @click="hideShowElements(index)" class="item-title-question"><span><i :class="(itemFaq.active) ? `bi bi-chevron-up me-1` : `bi bi-chevron-down me-1`"></i></span> <strong>{{ itemFaq.title }}</strong></p>
        <p class="ms-3" v-if="itemFaq.active">{{ itemFaq.body }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Faqs',
  data() {
    return {
      faqs: [
        {
          title: '¿Cómo se siente un vuelo en globo aerostático?',
          body: 'Aunque definitivamente sentirte nervioso antes del vuelo en globo es totalmente normal, no hay nada más increíble que flotar en un globo aerostático y ver el místico paisaje de Teotihuacan. Lo que menos sentirás será vértigo, náuseas o algún otro síntoma incómodo, porque la belleza del paisaje y la seguridad que te dará todo nuestro equipo con su experiencia, será suficiente para que disfrutes del paseo en globo aerostático y aterrices totalmente encantado. Volar es maravilloso, te da una increíble sensación de paz, romanticismo, libertad, y por qué no decirlo, de felicidad. ¡Vale la pena vivirlo!',
          active: false,
        },
        {
          title: '¿Cuántas personas caben en un globo aerostático?',
          body: 'La canastilla y la vela del globo aerostático están diseñadas para diferentes capacidades de peso. En Sky Balloons, contamos con más de 10 globos aerostáticos que pueden llevar desde 2 pasajeros (más el piloto) hasta un grupo de 14 viajeros, llegando a soportar más de una tonelada de peso, 1,100 kg. Aproximadamente. Si quieres venir con tu grupo de amigos, colegas de trabajo, o en una excursión, ¡sin duda habrá lugar para todos!',
          active: false,
        },
        {
          title: '¿Es una actividad segura?',
          body: 'Completamente segura. Los pilotos certificados y los globos aerostaticos matriculados son la clave de un vuelo en globo aerostatico seguro. Además, siempre estamos atentos a factores externos como el clima o la dirección del viento para asegurarte un viaje perfecto. Sólo necesitas seguir las recomendaciones de seguridad en todo momento y la experiencia de nuestro equipo será suficiente para que tu paseo en Teotihuacan sea placentero, mágico, y libre de riesgos.',
          active: false,
        },
        {
          title: '¿A qué hora es el vuelo?',
          body: 'La cita es alrededor de las 6 am en nuestro globopuerto. Te hacemos madrugar por una razón muy importante: Tu seguridad. Sí, el tiempo adecuado para volar es durante las primeras horas de la mañana, aproximadamente de 6:30 a.m. a 8:00 a.m. ya que la temperatura ideal (sin alteraciones por el inicio del calentamiento de la atmósfera) y el viento suave, son excelentes elementos para un vuelo en globo seguro. Los pilotos deben evitar volar mientras existan corrientes de aire, vientos fuertes o lluvia y esto sucede exclusivamente a esta hora del día, lo vuelos en globo en todo el mundo se llevan a cabo en este horario, sin embargo, ¡Te aseguramos que vale la pena madrugar!',
          active: false,
        },
        {
          title: '¿Los niños pueden realizar la actividad de vuelo en globo?',
          body: '¡Claro!, los niños mayores de 4 años son bienvenidos en la aventura de subir al globo aerostático. Además de que les encanta ver los coloridos globos y sus impresionantes tamaños, la canastilla es lo suficientemente alta para que el menor se sienta cómodo y pueda disfrutar de este gran viaje en globo. En compañía de sus padres, sin duda esta actividad puede ser fantástica para los niños aventureros.',
          active: false,
        },
        {
          title: '¿Cuánto tiempo dura la actividad?',
          body: 'El vuelo en globo sobre Teotihuacán tiene una duración de 30-45 minutos aproximadamente. No obstante, cada vuelo es diferente y eso puede llevar a cada piloto a tomar la decisión de aterrizar antes o de prolongar un poco más el vuelo para que el aterrizaje se realice en un lugar y condiciones óptimas, desde el punto de vista de la seguridad como en el de la recogida del globo.',
          active: false,
        }
      ],
    }
  },
  methods: {
    hideShowElements(index){
      // unchecking all
      this.faqs[index].active = !this.faqs[index].active

      this.faqs.map((el, elIndex) => {
        if(elIndex !== index) {
          return el.active = false
        }
      })

      // this.faqs[index].active = !this.faqs[index].active
    },
  }
}
</script>

<style scoped>
</style>