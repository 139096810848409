<template>
  <div>
    <h1 class="border-bottom pb-4 text-secondary-emphasis fw-bold">{{ $t('ready_for_booking') }}</h1>
    <div class="mt-3">
      <div class="row">

        <div class="col-lg-12">
          <p class="lead"><i class="bi bi-1-circle"></i> Escoja una fecha de vuelo</p>
          <v-date-picker
              :attributes="calendarAttributes"
              :locale="selectedLocale"
              :min-date="new Date()"
              @dayclick="chooseFlightDate"
              :select-attribute="selectAttribute"
              :disabled-dates="datesDisabled"
              :model-config="modelConfig"
              v-model="flightDate"
              :columns="1"
              :rows="1"
              is-expanded></v-date-picker>
        </div>

        <div class="col-lg-12">
          <p class="lead mt-3"><i class="bi bi-2-circle"></i> {{ $t('quick_bar.select_flight_title') }}</p>
          <div class="position-relative">
            <div class="position-relative">
              <input type="text" id="search-flight" class="form-control custom-search shadow-sm pe-5" :placeholder="$t('quick_bar.placeholder_select_flight')">
              <i class="bi bi-chevron-down position-absolute icon-action-flight" style="top: 50%; right: 15px; transform: translateY(-50%);" @click="toggleFlightList"></i>
            </div>
            <div class="flights-list custom-flights-list-shadow" v-if="showFlightList">
              <!-- Flight List -->
              <div class="flight-list bg-white rounded">
                <SelectFlightItem
                    v-for="itemFlight in flightItemsSearch"
                    @flight-selected="finishToCheckout"
                    :item-flight="itemFlight"
                    :key="itemFlight.flight_id"></SelectFlightItem>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SelectFlightItem from "@/views/templates/SelectFlightItem.vue";

export default {
  components: {
    SelectFlightItem
  },
  props: ['externalHideSearchInput'],
  data() {
    return {
      loadingSearchingFlightDate: false,
      isFlightDateAvailableToBook: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      datesDisabled: [],
      selectAttribute: {
        dot: true,
      },
      flightDate: null,
      selectedLocale: 'es',
      calendarAttributes: [],
      showFlightList: false,  // Toggle flight list visibility
      selectedOption: null,
      flightItems: [],
      flightItemsSearch: [],
      options: []
    };
  },
  watch: {
    'externalHideSearchInput': function(newVal, oldVal) {
      this.showFlightList = !newVal
    }
  },
  mounted() {
    // Initialize Bootstrap tooltips


    this.getHomeFlightsSearchInput()
  },
  methods: {
    chooseFlightDate() {
      this.showFlightList = true
    },
    isDateAvailableToBook(date) {
      return this.datesDisabled.some(disabledRange => {
        const startDate = new Date(disabledRange.start);
        const endDate = new Date(disabledRange.end);

        // Comparar la fecha seleccionada con los rangos deshabilitados
        return date >= startDate && date <= endDate;
      });

    },
    searchAvailabilityDate({date}) {
      this.loadingSearchingFlightDate = true
      this.isFlightDateAvailableToBook = false
      if (this.isDateAvailableToBook(date)) {
        Swal.fire({
          icon: 'error',
          title: 'Fecha no disponible',
          text: 'La fecha seleccionada está deshabilitada porque no hay disponibilidad de vuelo para esta fecha',
          confirmButtonText: 'Intentar de nuevo'
        });
        this.flightDate = null
        this.loadingSearchingFlightDate = false
        //console.log('Fecha deshabilitada seleccionada:', date);
        return; // No hacer nada si la fecha está deshabilitada
      }

      this.$http.post(`/manage-logistic/get-status-availability-by-flight-date`, {
        flight_date: this.flightDate,
        category_flight_id: parseInt(this.finalCart.flight.flight_category_id),
        number_pax_to_book: this.getNumberPassengers(),
      })
          .then((res) => {

            if (res.data.is_date_available_to_book === 1) {
              this.isFlightDateAvailableToBook = true
            } else {
              this.flightDate = null

              Swal.fire({
                icon: 'error',
                title: 'Fecha no disponible',
                text: 'No se encontró disponibilidad para esta fecha de vuelo, por favor elija una diferente',
                confirmButtonText: 'Intentar de nuevo'
              });
            }

          }).finally(() => {
        this.loadingSearchingFlightDate = false
      })
    },
    toggleFlightList() {
      // Toggle visibility of the flight list
      this.showFlightList = !this.showFlightList;
      if (this.showFlightList && this.flightItems.length === 0) {
        // Fetch flight items if the list is shown and not loaded
        this.getHomeFlightsSearchInput();
      }
    },
    getHomeFlightsSearchInput() {
      this.$http.get(`/manage-services/flights/get-flights`)
          .then((res) => {
            this.flightItemsSearch = res.data.data
          }).finally(() => {
        const tooltipTriggerList = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

        //this.showFlightList = true
      })
    },

    finishToCheckout(itemFlight) {
      let itemsCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      localStorage.setItem('items-cart', JSON.stringify(itemFlight))

      let items = JSON.parse(localStorage.getItem('items-cart')) || {}
      const lang = localStorage.getItem('language') || 'en';

      this.$router.push({
        name: 'checkout-' + lang,
        params: {
          externalFlightDate: this.flightDate,
        }
      });
    }
  }
}
</script>
