<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light p-2">
      <div class="container-fluid">
        <a class="navbar-brand logo-info" href="/public">
          <img src="/logo.png" alt="Logo" width="60" class="d-inline-block align-text-top">
          {{ $t('logo_site_name') }}
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <template v-for="itemMenu in menuItems">
              <template v-if="itemMenu.menu_subitems.length > 0">
                <li
                    @click="handleClick(itemMenu)"
                    @mouseover="handleMouseOver(itemMenu)"
                    @mouseleave="handleMouseLeave(itemMenu)"
                    class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" :href="getMenuLink(itemMenu)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                    >
                    <i class="bi bi-chevron-compact-right"></i> {{ itemMenu.menu_title }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li v-for="itemSubmenu in itemMenu.menu_subitems" class="custom-height-link-menu-border">
                      <a class="dropdown-item custom-height-link-menu" :href="getFlightLink(itemSubmenu)" v-html="itemSubmenu.flight_name"></a></li>
                  </ul>
                </li>
              </template>
              <template v-else>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" :href="getMenuLink(itemMenu)">
                    <i class="bi bi-chevron-compact-right"></i> {{ itemMenu.menu_title }}
                  </a>
                </li>
              </template>
            </template>
            <li class="nav-item">
              <a class="nav-link" :href="getLocalizedUrl('en')" @click.prevent="changeLanguage('en')"><img class="img-fluid" src="/usa.svg" alt="SkyBalloons United States" width="25" height="25"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="getLocalizedUrl('es')" @click.prevent="changeLanguage('es')"><img class="img-fluid" src="/mx.svg" alt="SkyBalloons Mexico" width="25" height="25"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-bold" @click="goToShoppingCart" href="#!">
                <i class="bi bi-cart"></i> ({{counterItemsCart}}) {{ $t('menu_bar.shopping_cart_text') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: 'Menu',
  data() {
    return {
      flightItems: [],
      menuAdditionalServicesList: [],
      counterItemsCart: 0,
      menuItems: [],
    }
  },
  created() {
    this.translatedMenuItems()

  },
  mounted(){

    // Observar cambios en el idioma seleccionado
    this.$watch('$i18n.locale', (newLocale, oldLocale) => {
      this.translatedMenuItems();  // Llamar nuevamente a la función de traducción
    });
    setInterval(() => {
      // get flight cart
      const flightCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // get additional services cart
      const additionalServicesCart = JSON.parse(localStorage.getItem('items-additional-services-cart')) || []
      this.counterItemsCart = 0

      if( flightCart && flightCart.flight_id ) {
        this.counterItemsCart = 1
      }
      if( additionalServicesCart && additionalServicesCart.length > 0 ) {
        this.counterItemsCart = this.counterItemsCart + (additionalServicesCart.length)
      }
    }, 500)
  },
  methods: {
    handleClick(itemMenu) {
      window.location.href = this.getMenuLink(itemMenu)
    },
    handleMouseOver(itemMenu) {

      if (itemMenu.menu_key === 'flights') {
        // Obtén el dropdown del DOM
        const dropdownMenu = event.currentTarget.querySelector('.dropdown-menu');

        // Usa Bootstrap's API para mostrar el dropdown
        if (dropdownMenu) {
          const bootstrapDropdown = new bootstrap.Dropdown(dropdownMenu.previousElementSibling);
          bootstrapDropdown.show();
        }
      }
    },

    handleMouseLeave(itemMenu) {
      if (itemMenu.menu_key === 'flights') {
        // Obtén el dropdown del DOM
        const dropdownMenu = event.currentTarget.querySelector('.dropdown-menu');

        // Usa Bootstrap's API para ocultar el dropdown
        if (dropdownMenu) {
          const bootstrapDropdown = new bootstrap.Dropdown(dropdownMenu.previousElementSibling);
          bootstrapDropdown.hide();
        }
      }
    },
    getHomeFlights() {
      return this.$http.get(`/manage-services/flights/get-flights`)
    },
    getMenuLink(itemMenu) {
      const lang = localStorage.getItem('language') || 'en';
      const routeName = `${itemMenu.menu_key}-${lang}`;  // Nombre de la ruta, ej. 'home-es' o 'services-en'

      // Busca la ruta que coincida con el 'name'
      const matchedRoute = router.options.routes.find(route => route.name === routeName);

      if (matchedRoute) {
        return matchedRoute.path;  // Retorna la ruta encontrada
      } else {
        console.warn(`No se encontró la ruta para: ${routeName}`);
        return '/';  // Valor por defecto si no se encuentra la ruta
      }
    },
    getFlightLink(itemSubMenu) {
      const lang = localStorage.getItem('language') || 'en';
      const routeName = `flight-details-${lang}`;  // Nombre de la ruta, ej. 'flight-details-en' o 'flight-details-es'

      // Busca la ruta que coincida con el 'name'
      const matchedRoute = router.options.routes.find(route => route.name === routeName);

      if (matchedRoute) {
        // Reemplaza el parámetro :slug con el valor real de itemSubMenu.flight_slug
        const flightSlug = itemSubMenu.flight_slug;
        const pathWithSlug = matchedRoute.path.replace(':slug', flightSlug);

        return pathWithSlug;  // Retorna la ruta completa con el slug
      } else {
        console.warn(`No se encontró la ruta para: ${routeName}`);
        return '/';  // Valor por defecto si no se encuentra la ruta
      }
    },
    translatedMenuItems() {
      this.getHomeFlights()
          .then((res) => {
            this.menuItems = [
              {
                menu_key: 'home',
                menu_title: this.$t('menu_bar.home_item_text'),
                menu_subitems: [],
              },
              {
                menu_key: 'flights',
                menu_title: this.$t('menu_bar.flights_text'),
                menu_subitems: res.data.data.map((item) => {
                  return {
                    flight_name: item.flight_name,
                    flight_slug: item.flight_slug
                  };
                }),
              },
              {
                menu_key: 'services',
                menu_title: this.$t('menu_bar.additional_services_text'),
                menu_subitems: [],
              },
              {
                menu_key: 'about-us',
                menu_title: this.$t('menu_bar.more_about_us_text'),
                menu_subitems: [],
              }
            ];
          })

    },
    goToShoppingCart(){
      const lang = localStorage.getItem('language') || 'en';

      this.$router.push({
        name: 'checkout-' + lang
      })
    },
    // Método para construir la URL con el idioma seleccionado
    getLocalizedUrl(lang) {
      // Obtiene la ruta actual sin el prefijo de idioma
      const currentPath = this.$route.path;
      // Si la ruta ya tiene un prefijo de idioma, lo elimina
      const normalizedPath = currentPath.replace(/^\/(en|es)/, '');
      // Construye la URL con el idioma seleccionado
      return `/${lang}${normalizedPath}`;
    },
    // Método para cambiar el idioma
    changeLanguage(lang) {
      // Construye la nueva ruta con el idioma seleccionado
      const currentPath = this.$route.path;
      const normalizedPath = currentPath.replace(/^\/(en|es)/, '');
      const newPath = `/${lang}${normalizedPath}`;

      // Solo navega si la nueva ruta es diferente de la ruta actual
      if (this.$route.path !== newPath) {
        this.$router.push({ path: newPath });
      }
    }
  }
};
</script>