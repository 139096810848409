<template>
  <div>
    <template v-if="suggestedFlights && suggestedFlights.length > 0">
      <VueSlickCarousel ref="slick" v-bind="slickOptions">
        <div
            :key="itemSuggestedFlight.flight_id" v-for="itemSuggestedFlight in suggestedFlights"
            class="p-3">
          <FlightCard :custom-class="'inner-flights-item-slider'" :flight-slider-item="itemSuggestedFlight" ></FlightCard>
        </div>
      </VueSlickCarousel>
    </template>
    <template v-else>
      ...
    </template>
  </div>
</template>

<script>
import FlightCard from "@/views/templates/FlightCard.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'MoreFlightsSlider',
  components: {FlightCard, VueSlickCarousel},
  props: ['suggestedFlights'],
  data() {
    return {
      slickOptions: {
        infinite: true,
        slidesToShow: 4, // Número de elementos en pantalla grande
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
        responsive: [
          {
            breakpoint: 1024, // Para pantallas menores a 1024px (tableta y móviles)
            settings: {
              slidesToShow: 3, // Número de elementos en pantallas de tableta
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768, // Para pantallas menores a 768px (móviles)
            settings: {
              slidesToShow: 1, // Número de elementos en móviles
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
</style>