import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles.css'; // Importa tu archivo CSS
import 'bootstrap-icons/font/bootstrap-icons.css';
import { numberFormat } from '@/utils/numberFormat';
import axios from 'axios';
import i18n from './i18n';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { createHead } from '@unhead/vue';
import VCalendar from 'v-calendar';


// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: 'v',
    locales: {
        es: {
            firstDayOfWeek: 2, // Lunes como primer día de la semana
            masks: {
                L: 'DD/MM/YYYY',  // Formato de fecha
            },
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            // Otras configuraciones específicas del idioma español
        },
        en: {
            firstDayOfWeek: 1, // Domingo como primer día de la semana
            masks: {
                L: 'MM/DD/YYYY',  // Formato de fecha en inglés
            },
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            // Otras configuraciones específicas del idioma inglés
        }
    },
    locale: 'es'  // Aquí seleccionas el idioma predeterminado (español en este caso)
});
const head = createHead();
// Crear una instancia personalizada de axios con la URL base configurada y cabeceras
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'multipart/form-data', // Establecer el tipo de contenido como JSON
    }
});

// Agregar interceptores a la instancia personalizada de axios
axiosInstance.interceptors.response.use(
    response => {
      // Cualquier respuesta que no sea un error, simplemente retornarla
      return response;
    },
    error => {
      // Manejar errores 404 y 500
      if (error.response) {
        if (error.response.status === 404) {
          console.error('Error 404: Recurso no encontrado.');
        } else if (error.response.status === 500) {
          console.error('Error 500: Error interno del servidor.');
        }
      }
      // Rechazar la promesa con el error para que pueda ser manejado por los llamadores
      return Promise.reject(error);
    }
);

// Asignar la instancia personalizada al prototipo de Vue
Vue.prototype.$http = axiosInstance;

// Añadir la función al prototipo de Vue
Vue.prototype.$number_format = numberFormat;

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  head,
  render: function (h) { return h(App); },
}).$mount('#app');
