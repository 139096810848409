<template>
  <div>
    <div class="container pt-5 pb-5">
      <h1>Carrito de compras</h1>
      <hr>

      <template v-if="flightCart && flightCart.flight_id">
        <div class="alert alert-primary alert-dismissible fade show" role="alert">
          Los servicios adicionales agregados previamente aparecerán listados en el carrito de compras
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <!--        <button @click="testvalidateFirstStepForm">VALIDAR PRIMER PASO</button>
                <button @click="testValidatePassengers">VALIDAR PASAJEROS</button>
                <button @click="getTotalsCart">CALCULAR TOTALES</button>
                <button @click="searchAvailabilityDate">SEARCH AVAILABILITY DATE</button>-->

        <div>
          <h2 class="text-primary">Finaliza tu compra</h2>
          <div class="row">
            <!-- Navegación de los pasos -->
            <div class="col-md-4 mb-3">
              <ul class="nav flex-column nav-pills">
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: currentStep === 1 }" @click="goToStep(1)" href="#">1.
                    Personaliza
                    tu reserva</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: currentStep === 2, disabled: !isStep1Complete }"
                     @click="goToStep(2)" href="#">2. Información de Pago</a>
                </li>
              </ul>

              <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                <h5>Resumen de su compra</h5>
                <div>
                  <table class="table shopping-cart-totals-table" v-if="totalsCart">
                    <tr>
                      <td class="p-2">
                        <p class="text-start m-0 p-0"><strong>Fecha de vuelo:</strong></p>
                      </td>
                      <td class="p-2">
                        <span class="badge bg-warning text-dark" v-if="!flightDate || !isFlightDateAvailableToBook">Esperando fecha de vuelo</span>
                        <p class="m-0 p-0" v-else>{{ formatToFriendlyDate(flightDate) }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-2">
                        <p class="text-start m-0 p-0"><strong>Vuelo(s):</strong></p>
                      </td>
                      <td class="p-2">{{ totalsCart.flight.details.flight_name }}</td>
                    </tr>
                    <tr>
                      <td class="p-2">
                        <p class="text-start m-0 p-0"><strong>Pasajero(s):</strong></p>
                      </td>
                      <td class="p-2">
                        <ul class="list-unstyled m-0 p-0">
                          <li v-for="(itemPassenger, index) in totalsCart.flight.passengers"
                              :class="{'p-0 pb-3 m-0 border-bottom': true, 'pt-3': index !== 0}">
                            ({{ itemPassenger.quantities }}) {{ itemPassenger.unit_name }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-2">
                        <p class="text-start m-0 p-0"><strong>Servicios adicionales:</strong></p>
                      </td>
                      <td class="p-2">
                        <ul class="list-unstyled m-0 p-0">
                          <li :class="{'p-0 pb-3 m-0 border-bottom': true, 'pt-3': index !== 0}"
                              v-for="(service, index) in totalsCart.services">({{ service.quantities }})
                            {{ service.additional_service_description_es }} (p/{{ service.unit_name }})
                          </li>
                        </ul>
                      </td>
                    </tr>

                  </table>

                  <table class="table">
                    <tr class="">
                      <td>
                        <p class="m-0 p-0 text-success">Subtotal vuelos:</p>
                      </td>
                      <td>
                        <p class="m-0 p-0 text-end">
                          {{ getFrontendOnlyPrice(totalsCart.subtotal_flight) }}
                        </p>
                      </td>
                    </tr>
                    <tr class="">
                      <td>
                        <p class="m-0 p-0 text-success">Subtotal servicios adicionales:</p>
                      </td>
                      <td>
                        <p class="m-0 p-0 text-end">
                          {{ getFrontendOnlyPrice(totalsCart.subtotal_services) }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="pt-3"></td>
                    </tr>

                    <tr class="">
                      <td>
                        <p class="m-0 p-0 text-success"><strong>Subtotal:</strong></p>
                      </td>
                      <td>
                        <p class="m-0 p-0 text-end">
                          {{ getFrontendOnlyPrice(totalsCart.subtotal_global_services) }}
                        </p>
                      </td>
                    </tr>

                    <template v-if="discountCouponApplied">
                      <tr class="">
                        <td>
                          <p class="m-0 p-0 text-success"><i class="bi bi-info-circle"></i> Descuentos:</p>
                        </td>
                        <td>
                          <p class="m-0 p-0 text-end text-success">
                            {{ getFrontendDiscountString(discountCouponApplied) }}
                          </p>
                        </td>
                      </tr>
                    </template>

                    <tr class="" v-if="totalsCart.charges && totalsCart.charges > 0">
                      <td>
                        <p class="m-0 p-0 text-danger"><i class="bi bi-info-circle"></i> Cargos adicionales:</p>
                      </td>
                      <td>
                        <p class="m-0 p-0 text-end text-danger">
                          {{ getFrontendOnlyPrice(totalsCart.charges) }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p class="m-0 p-0 text-success">
                          <span class="badge bg-success">Total:</span>
                        </p>
                      </td>
                      <td>
                        <p class="m-0 p-0 text-end">
                          <strong>${{ getFrontendOnlyPrice(totalsCart.total_global) }}</strong>
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>

                <div>
                  <div v-show="!allowToApplyCoupons" class="alert alert-warning" role="alert">
                    <strong>Debe seleccionar primero una fecha de vuelo</strong> para aplicar un cupón de descuento
                  </div>

                  <p>¿Tienes un cupón de descuento?</p>
                  <div class="input-group mb-3">
                    <input :disabled="!allowToApplyCoupons" type="text" class="form-control"
                           v-model="discountCouponText" placeholder="Ej: VERANOSKY24" aria-label="Recipient's username"
                           aria-describedby="button-addon2">
                    <button :disabled="!allowToApplyCoupons" @click="validateDiscountCoupon()"
                            class="btn btn-outline-primary" type="button" id="button-addon2">Validar
                    </button>
                  </div>
                </div>

              </div>
            </div>

            <!-- Contenido de los pasos -->
            <div class="col-md-8">
              <!-- Paso 1 -->
              <div v-if="currentStep === 1">
                <template v-if="finalCart.flight">
                  <div class="">
                    <h5 class="p-0 mb-3"><i class="bi bi-info-circle"></i> Estás comprando este vuelo</h5>

                    <div class="d-flex shadow-lg p-3 mb-3 bg-body rounded align-items-center">
                      <div class="flex-shrink-0">
                        <img class="rounded" :src="finalCart.flight.flight_thumbnail.path_image_small" alt="">
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <h5 class="text-primary">{{ finalCart.flight.flight_name }} <span
                            class="badge bg-warning text-dark">{{ finalCart.flight.flight_code }}</span> <span
                            class="badge bg-primary text-white">{{ finalCart.flight.flight_category_name }}</span></h5>
                        <p>{{ finalCart.flight.flight_s_description }}</p>
                      </div>
                    </div>

                    <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">


                      <div v-if="loadingSearchingFlightDate">
                        <div class="spinner-grow text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        Buscando disponibilidad de fecha, espere, por favor...
                      </div>

                      <template v-if="!loadingSearchingFlightDate">
                        <div v-if="(!flightDate || !isFlightDateAvailableToBook)">

                          <h5 class="pt-3 pb-3"><i class="bi bi-calendar-date"></i> Seleccione su fecha de vuelo</h5>

                          <div class="alert alert-light alert-dismissible fade show" role="alert">
                            <i class="bi bi-info-circle"></i> Del siguiente calendario deberá <strong>seleccionar una
                            fecha de vuelo válida</strong> de acuerdo a nuestra disponibilidad de vuelo
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>

                          <v-date-picker
                              :attributes="calendarAttributes"
                              :locale="selectedLocale"
                              :min-date="new Date()"
                              @dayclick="searchAvailabilityDate"
                              :select-attribute="selectAttribute"
                              :disabled-dates="datesDisabled"
                              :model-config="modelConfig"
                              v-model="flightDate"
                              :columns="1"
                              :rows="1"
                              is-expanded></v-date-picker>
                        </div>
                        <div v-else>

                          <div v-if="isFlightDateAvailableToBook" class="alert alert-success" role="alert">
                            <i class="bi bi-calendar-check"></i> La fecha de vuelo
                            <strong>{{ formatToFriendlyDate(flightDate) }} </strong> está disponible.
                          </div>
                          <a href="javascript:void(0);" @click="changeFlightDate">Deseo cambiar mi fecha de vuelo</a>
                        </div>
                      </template>

                    </div>

                    <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                      <h5 class="pt-3 pb-3"><i class="bi bi-bag-check"></i> Especifique los detalles de los pasajeros de
                        su vuelo</h5>
                      <div class="alert alert-light alert-dismissible fade show" role="alert">
                        Indique el número de pasajeros de su reserva. <strong>Recuerde verificar el tipo de
                        pasajero</strong>, es decir, revisar si es adulto o menor si fuera el caso.
                        <a href="#!">Conoce nuestra guía de pasajeros para elegir el que más se adapte a tus
                          necesidades</a>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      <table class="table">
                        <thead>
                        <tr>
                          <th>Precio</th>
                          <th>Pasajeros</th>
                          <th>Cantidades</th>
                          <th>Subtotal</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="itemPrice in finalCart.flight.passengers">
                          <td v-html="getFrontendOnlyPrice(itemPrice.price_per_item)"></td>
                          <td>{{ itemPrice.unit_name }}</td>
                          <td>
                            <template v-if="itemPrice.main_passenger === 1">
                              <select @change="updatePassengersCart(itemPrice)" style="text-align: center;"
                                      v-model="itemPrice.quantities" class="form-control">
                                <option v-for="i in 10" :value="(i)" v-html="(i)"
                                        :selected="((i)===itemPrice.quantities) ? 'selected' : ''"></option>
                              </select>
                            </template>
                            <template v-else>
                              <select @change="updatePassengersCart(itemPrice)" style="text-align: center;"
                                      v-model="itemPrice.quantities" class="form-control">
                                <option v-for="i in 11" :value="(i-1)" v-html="(i-1)"
                                        :selected="((i-1)===itemPrice.quantities) ? 'selected' : ''"></option>
                              </select>
                            </template>

                          </td>
                          <td>
                            {{
                              getFrontendPrice(convertPriceToFloat(itemPrice.quantities) * convertPriceToFloat(itemPrice.price_per_item))
                            }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                      <h5 class="pt-3 pb-3"><i class="bi bi-people"></i> Datos de los pasajeros</h5>
                      <div class="alert alert-light alert-dismissible fade show" role="alert">
                        A continuación especifique el nombre y correo electrónico del titular de la reserva, así como
                        algunos datos de los pasajeros
                        elegidos en el paso anterior
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>

                      <div class="p-3">
                        <div class="row">
                          <div class="col-lg-6 col-md-12 mb-3">
                            <label for=""><strong>Nombre(s)</strong></label>
                            <input v-model="mainPassengerInformation.full_name" class="form-control" type="text"
                                   placeholder="Ej: Pedro Juan">
                          </div>
                          <div class="col-lg-6 col-md-12 mb-3">
                            <label for=""><strong>Apellido(s)</strong></label>
                            <input v-model="mainPassengerInformation.lastname" class="form-control" type="text"
                                   placeholder="Ej: González Durán">
                          </div>
                          <div class="col-lg-12 col-md-12 mb-3">
                            <p class="m-0 p-0">
                              <small class=""><i class="bi bi-info-circle"></i> Recuerde incluir la clave LADA como "+52" antes del teléfono de 10 dígitos</small>
                            </p>
                            <label for="">
                              <strong>Teléfono de contacto</strong>
                            </label>
                            <input v-model="mainPassengerInformation.phone_contact" class="form-control" type="text"
                                   placeholder="Ej: +5299663322">
                          </div>
                          <div class="col-lg-6 col-md-12 mb-3">
                            <label for=""><strong>Correo electrónico</strong></label>
                            <input v-model="mainPassengerInformation.email" class="form-control" type="email"
                                   placeholder="Ej: tuemail@gmail.com">
                          </div>
                          <div class="col-lg-6 col-md-12 mb-3">
                            <label for=""><strong>Confirmar correo electrónico</strong></label>
                            <input v-model="mainPassengerInformation.email_confirmed" class="form-control" type="email"
                                   placeholder="Ej: tuemail@gmail.com">
                          </div>
                        </div>
                      </div>

                      <div>
                        <label for="continue-without-passengers">
                          <h5 class="text-primary"><input class="me-2 mt-5" v-model="continueWithoutPassengers"
                                                          type="checkbox" id="continue-without-passengers">
                            <i class="bi bi-info-circle"></i> Completar los detalles de mis pasajeros más adelante
                            durante mi Checkin
                          </h5>
                        </label>
                      </div>

                      <div v-if="!continueWithoutPassengers" v-for="(itemPrice, index) in finalCart.flight.passengers"
                           :key="index">
                        <template v-if="itemPrice.passenger_details && itemPrice.passenger_details.length > 0">
                          <template v-if="itemPrice.main_passenger === 1">
                            <tr>
                              <td colspan="2">
                                <label for="occupy-same-main-passenger-name"><input @change="occupyMainPassengerName"
                                                                                    id="occupy-same-main-passenger-name"
                                                                                    type="checkbox"> Ocupar el mismo
                                  nombre del titular</label>
                              </td>
                            </tr>
                          </template>
                          <table class="table">
                            <tr>
                              <td colspan="2">
                                <h3 class="p-0 m-0 text-primary">({{ itemPrice.passenger_details.length }})
                                  {{ itemPrice.unit_real_name_es }}</h3>
                              </td>
                            </tr>
                            <tr v-for="(itemPassenger, detailIndex) in itemPrice.passenger_details" :key="detailIndex">
                              <td class="p-3">
                                <label class="m-0 p-0" for="">
                                  <strong>Escriba su nombre completo</strong>
                                </label>
                                <input @keyup="validateFirstStepForm" v-model="itemPassenger.full_name" type="text"
                                       placeholder="Ej: Juanito Pérez"
                                       class="form-control">
                              </td>
                              <td class="p-3">
                                <label class="m-0 p-0" for="">
                                  <strong>Escriba su peso en KG aproximado</strong>
                                </label>
                                <input @keyup="validateFirstStepForm" v-model="itemPassenger.estimated_weight"
                                       type="number" min="0"
                                       placeholder="Ej: 89" class="form-control">
                              </td>
                            </tr>
                          </table>
                        </template>
                      </div>
                    </div>

                    <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                      <h5 class="pt-3 pb-3"><i class="bi bi-patch-plus"></i> Servicios adicionales</h5>
                      <div class="alert alert-light alert-dismissible fade show" role="alert">
                        Si añadiste algún servicio adicional a través de otras secciones de nuestro sitio web,
                        aparecerán
                        a continuación. De lo contrario <strong>aún tienes la opción de agregar los servicios
                        adicionales
                        que necesites desde este apartado</strong>.
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>

                      <h5 class="text-success p-0 mb-3">Servicios seleccionados
                        <strong>({{ finalCart.additionalServices.length }})</strong></h5>

                      <div class="services-cart-container border-top">
                        <template v-if="finalCart.additionalServices && finalCart.additionalServices.length > 0">

                          <div class="container">
                            <div class="row font-weight-bold">
                              <div class="col-1 d-none d-md-block"><strong>Imagen</strong></div>
                              <div class="col-4"><strong>Descripción</strong></div>
                              <div class="col-2"><strong>Precio</strong></div>
                              <div class="col-3"><strong>Cantidades</strong></div>
                              <div class="col-2"><strong>Subtotal</strong></div>
                            </div>

                            <div class="row border-bottom py-2" v-for="itemService in finalCart.additionalServices"
                                 :key="itemService.additional_service_id">
                              <!-- Imagen -->
                              <div class="col-1 d-none d-md-block">
                                <img class="img-fluid" :src="getThumbnailService(itemService)"
                                     :alt="itemService.alt_image_text">
                              </div>

                              <!-- Descripción -->
                              <div class="col-4">
                                <p class="m-0 p-0"><strong>{{ itemService.additional_service_description_es }}</strong>
                                </p>
                                <p class="m-0 p-0">{{
                                    (itemService.additional_service_long_description_es).substr(0, 99)
                                  }}...</p>

                                <button class="btn btn-sm btn-outline-danger mb-2 mt-2"
                                        @click="preRemoveAddSerFromCart(itemService)"><i class="bi bi-trash"></i>
                                  Eliminar
                                </button>
                              </div>

                              <!-- Precio -->
                              <div class="col-2">
                                <strong>{{ getFrontendPrice(itemService.additional_service_price_mxn) }}</strong>
                                <p class="m-0 p-0">p/<small>{{ itemService.unit_long_name_es }}</small></p>
                              </div>

                              <!-- Cantidades -->
                              <div class="col-3">
                                <select @change="updateServicesCart()" v-model="itemService.quantities" name="" id=""
                                        class="form-control">
                                  <option v-for="i in 10" :value="i" v-html="i"></option>
                                </select>
                              </div>

                              <!-- Subtotal -->
                              <div class="col-2">
                                {{ getSubtotalItemServiceCart(itemService) }}
                              </div>
                            </div>
                          </div>

                        </template>

                        <template
                            v-if="additionalServicesByCategoryAvailableNew && additionalServicesByCategoryAvailableNew.length > 0">
                          <div class="alert alert-light alert-dismissible fade show" role="alert">
                            <strong>Si necesita agregar más servicios adicionales puede hacerlo a partir de este
                              punto</strong> (los servicios adicionales que agregue se verán en la parte superior)
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                          <div v-for="itemParentService in additionalServicesByCategoryAvailableNew" class="mt-3 mb-3">

                            <h5 class="text-primary p-0 m-0">{{ itemParentService.category_name_es }}</h5>

                            <template
                                v-if="itemParentService.items_services && itemParentService.items_services.length > 0">
                              <div class="row font-weight-bold">
                                <div class="col-1 d-none d-md-block"><strong>Imagen</strong></div>
                                <div class="col-5"><strong>Descripción</strong></div>
                                <div class="col-3"><strong>Precio</strong></div>
                                <div class="col-3"><strong>Acciones</strong></div>
                              </div>


                              <div class="row border-bottom py-2"
                                   v-for="itemService in itemParentService.items_services"
                                   v-if="!existsInServicesA(itemService)">
                                <!-- Imagen -->
                                <div class="col-1 d-none d-md-block">
                                  <img class="img-fluid" :src="getThumbnailService(itemService)"
                                       :alt="itemService.alt_image_text">
                                </div>

                                <div class="col-5">
                                  <p class="m-0 p-0"><strong>{{
                                      itemService.additional_service_description_es
                                    }}</strong>
                                  </p>
                                  <p class="m-0 p-0">{{ itemService.additional_service_long_description_es }}</p>
                                </div>

                                <div class="col-3">
                                  <strong>{{ getFrontendPrice(itemService.additional_service_price_mxn) }}</strong>
                                  <p class="m-0 p-0">p/<small>{{ itemService.unit_long_name_es }}</small></p>
                                </div>

                                <div class="col-3">
                                  <button @click="addAddSerToCart(itemService)" class="btn btn-md btn-primary"><i
                                      class="bi bi-bag-plus"></i> Añadir
                                  </button>
                                </div>
                              </div>
                            </template>

                            <template v-else>
                              <p>¡Genial! Ya agregaste todos los servicios adicionales de esta sección.</p>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div v-if="!canProceedPaymentForm" class="alert alert-light alert-dismissible fade show" role="alert">
                    <i class="bi bi-info-circle"></i> Por favor verifique que todos sus datos sean válidos antes de continuar.
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>

                  <div v-if="canProceedPaymentForm" class="alert alert-success alert-dismissible fade show" role="alert">
                    <i class="bi bi-check-circle"></i> ¡Perfecto, ahora puedes continuar con el pago!
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>

                  <button :disabled="!canProceedPaymentForm" @click="proceedPaymentForm()"
                          class="btn btn-primary btn-lg"><i class="bi bi-arrow-right"></i> Proceder al pago
                  </button>
                </template>
              </div>

              <!-- Paso 2 -->
              <div v-if="currentStep === 2">
                <h4><i class="bi bi-bank"></i> Información de Pago</h4>

                <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                  <h5 class="pt-3 pb-3"><i class="bi bi-arrow-right-circle-fill"></i> Especifique su forma de pago</h5>

                  <div class="row">
                    <div class="col-sm-4 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">Pago con tarjeta de crédito/débito</h5>
                          <p class="card-text">With supporting text below as a natural lead-in to additional content. <a
                              href="#!"><i class="bi bi-arrow-up-right"></i> Ver más</a></p>
                          <a href="#" class="btn btn-dark">Seleccionar</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">Pago en efectivo (tiendas de conveniencia)</h5>
                          <p class="card-text">With supporting text below as a natural lead-in to additional content. <a
                              href="#!"><i class="bi bi-arrow-up-right"></i> Ver más</a></p>
                          <a href="#" class="btn btn-dark">Seleccionar</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">Pago en efectivo (Tiendas OXXO)</h5>
                          <p class="card-text">With supporting text below as a natural lead-in to additional content. <a
                              href="#!"><i class="bi bi-arrow-up-right"></i> Ver más</a></p>
                          <a href="#" class="btn btn-dark">Seleccionar</a>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">Pago por transferencia SPEI (Bancarias)</h5>
                          <p class="card-text">With supporting text below as a natural lead-in to additional content. <a
                              href="#!"><i class="bi bi-arrow-up-right"></i> Ver más</a></p>
                          <a href="#" class="btn btn-dark">Seleccionar</a>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <!--Credit debit card payment-->
                <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                  <h5 class="pt-3 pb-3"><i class="bi bi-credit-card"></i> Información de su tarjeta</h5>
                  <!-- Formulario de pago -->
                  <form @submit.prevent="createToken">
                    <div class="row">
                      <div class="mb-3 col-lg-12 col-md-12 col-md-12">
                        <label for="cardHolderName" class="form-label">Nombre del titular de la tarjeta</label>
                        <input
                            placeholder="Nombre completo del titular"
                            type="text"
                            class="form-control"
                            id="cardHolderName"
                            v-model="paymentData.cardHolderName"
                            @input="formatCardHolderName"
                            required>
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-3 col-lg-6 col-md-12 col-md-12">
                        <label for="cardNumber" class="form-label">Número de Tarjeta</label>
                        <input
                            placeholder="XXXX XXXX XXXX XXXX"
                            type="text"
                            class="form-control"
                            id="cardNumber"
                            v-model="formattedCardNumber"
                            @input="formatCardNumber"
                            required>
                      </div>
                      <div class="mb-3 col-lg-3 col-md-6 col-md-12">
                        <label for="expirationDate" class="form-label">Fecha de Expiración</label>
                        <input
                            placeholder="MM/YY"
                            type="text"
                            class="form-control"
                            id="expirationDate"
                            v-model="paymentData.expirationDate"
                            @input="formatExpirationDate"
                            required>
                      </div>
                      <div class="mb-3 col-lg-3 col-md-6 col-md-12">
                        <label for="cvv" class="form-label">CVV</label>
                        <input
                            placeholder="***"
                            type="password"
                            class="form-control"
                            id="cvv"
                            v-model="paymentData.cvv"
                            @input="validateCVV"
                            required>
                      </div>
                    </div>

                    <div v-if="showLoadingPaymentContainer" class="loading-payment-container">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      Procesando pago, no cierre esta ventana...
                    </div>

                    <button v-if="!showLoadingPaymentContainer" type="submit" class="btn btn-dark btn-lg">Pagar</button>

                    <div class="mt-2 mb-2" v-if="showSuccessPaymentCardMessage">
                      <div class="alert alert-success" role="alert">
                        Su pago se realizó con éxito. En breve recibirá los detalles de su compra. <a href="#!">Ver mi
                        recibo de compra</a>
                      </div>
                    </div>

                    <div class="mt-2 mb-2" v-if="showErrorPaymentCardMessage">
                      <div class="alert alert-danger" role="alert">
                        No pudimos procesar su pago. Favor de intentarlo nuevamente, si persiste el error de su pago,
                        favor de intentarlo con otro medio de pago, o contactar a su banco.
                      </div>
                    </div>

                    <div class="mt-3">
                      <label for="accept-privacy-terms">
                        <input type="checkbox" id="accept-privacy-terms" v-model="acceptTerms"> Al continuar, acepta
                        usted los <a href="#!">términos y condiciones</a> y nuestro <a href="#!">aviso de privacidad</a>
                      </label>
                    </div>
                  </form>

                </div>


                <!--Store payment-->
                <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                  <h5 class="pt-3 pb-3"><i class="bi bi-1-circle"></i> Pago en tiendas de conveniencia</h5>
                  <div class="alert alert-light alert-dismissible fade show" role="alert">
                    <i class="bi bi-info-circle"></i> Su pago puede realizarlo a través de diferentes comercios
                    alrededor de México presentando la referencia que le proporcionaremos. Una vez el comercio nos
                    notifique su pago, nosotros confirmaremos su reserva a través de los medios de contacto
                    proporcionados. <a href="#!">Conozca los establecimientos autorizados</a>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>

                  <div v-if="showLoadingPaymentContainer" class="loading-payment-container">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Procesando referencia, espere, por favor...
                  </div>

                  <button v-if="!showLoadingPaymentContainer" @click="getStoresReferenceDoc"
                          class="btn btn-dark btn-lg m-0"><i class="bi bi-download"></i> Descargar referencia y
                    finalizar
                  </button>

                </div>


                <!--Bank payment-->
                <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                  <h5 class="pt-3 pb-3"><i class="bi bi-3-circle"></i> Pago por transferencia SPEI</h5>
                  <div class="alert alert-light alert-dismissible fade show" role="alert">
                    <i class="bi bi-info-circle"></i> SPEI (Sistema de Pagos Electrónicos Interbancarios) le permite
                    hacer una transferencia bancaria hacia nuestro comercio utilizando los datos de la referencia que le
                    proporcionaremos. Una vez el banco nos notifique su pago, nosotros confirmaremos su reserva a través
                    de los medios de contacto proporcionados. <a href="#!">Conozca los bancos permitidos para
                    transferencias</a>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>

                  <div v-if="showLoadingPaymentContainer" class="loading-payment-container">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Procesando referencia, espere, por favor...
                  </div>
                  <button v-if="!showLoadingPaymentContainer" @click="getBanksReferenceDoc"
                          class="btn btn-dark btn-lg m-0"><i class="bi bi-download"></i> Descargar referencia SPEI
                  </button>

                </div>

                <!--Store  OXXO payment-->
                <div class="mt-3 shadow-lg p-3 mb-3 bg-body rounded">
                  <h5 class="pt-3 pb-3"><i class="bi bi-2-circle"></i> Pago en tiendas de OXXO</h5>
                  <div class="alert alert-light alert-dismissible fade show" role="alert">
                    <i class="bi bi-info-circle"></i> Su pago lo puede realizar en cualquier tienda OXXO alrededor de
                    México al presentar la referencia que le proporcionaremos. Una vez el comercio nos notifique su
                    pago, nosotros confirmaremos su reserva a través de los medios de contacto proporcionados. <a
                      href="#!">Conozca su Tienda OXXO más cercana</a>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>

                  <div v-if="showLoadingPaymentContainer" class="loading-payment-container">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Procesando referencia, espere, por favor...
                  </div>
                  <button v-if="!showLoadingPaymentContainer" class="btn btn-dark btn-lg m-0"><i
                      class="bi bi-download"></i> Descargar referencia OXXO y finalizar
                  </button>

                </div>


              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="alert alert-primary" role="alert">
          Su carrito de compras se encuentra vacío. <a href="javascript:void(0);" @click="flightsRedirect()">Agregue al
          menos un servicio de vuelo en globo para continuar</a>
        </div>
      </template>


    </div>
  </div>
</template>

<script>
import AServiceCardLg from "@/views/templates/AServiceCardLg.vue";
import AServiceCardCart from "@/views/templates/AServiceCardCart.vue";
import moment from 'moment-timezone';
import PaymentIcons from "@/views/templates/PaymentIcons.vue";

export default {
  name: 'CheckoutView',
  components: {PaymentIcons, AServiceCardCart, AServiceCardLg},
  props: ['salesQuoteId', 'externalFlightDate'],
  data() {
    return {
      showSuccessPaymentCardMessage: false,
      showErrorPaymentCardMessage: false,
      showLoadingPaymentContainer: false,
      continueWithoutPassengers: true,
      mainPassengerInformation: {
        full_name: '',
        lastname: '',
        email: '',
        email_confirmed: '',
        phone_contact: ''
      },
      calendarAttributes: [],
      dates_availability: {
        //medium_availability: ["2024-08-19", "2024-08-23", "2024-08-25"],
        //low_availability: ["2024-08-20", "2024-08-21", "2024-08-22"],
        medium_availability: [],
        low_availability: [],
      },
      selectedLocale: 'es', // Idioma inicial
      selectAttribute: {
        dot: true,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      allowToApplyCoupons: false,
      discountCouponApplied: false,
      discountCouponText: '',
      canProceedPaymentForm: false,
      totalsCart: null,
      finalCart: {},
      flightCart: null,
      additionalServicesCart: null,
      currentStep: 1,
      isStep1Complete: false,
      passengerData: {
        name: '',
        email: ''
      },
      additionalServicesByCategoryAvailable: [],
      additionalServicesByCategoryAvailableNew: [],
      listDatesDisabledAvailability: null,
      datesDisabled: [],
      flightDate: null,
      showErrorFlightDateNoAvailable: false,
      isFlightDateAvailableToBook: false,
      loadingSearchingFlightDate: false,
      paymentData: {
        cardHolderName: '',
        cardNumber: '',
        expirationDate: '',
        cvv: ''
      },
      formattedCardNumber: '', // Card number formatted with spaces
      acceptTerms: false,

    }
  },
  created() {
    //console.log(this.salesQuoteId)


    if (this.salesQuoteId && parseInt(this.salesQuoteId) > 0) {
      this.getSalesQuoteBySalesQuoteId(this.salesQuoteId)
    } else {

      this.flightCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      if (this.flightCart && this.flightCart.flight_id) {
        this.getShoppingCartItems()
        this.getAdditionalServices()
        this.setCalendarAttributes(new Date());

        if(this.externalFlightDate && this.externalFlightDate.trim().length > 0) {
          this.flightDate = this.externalFlightDate
          const formattedDate = moment.tz(this.flightDate, 'YYYY-MM-DD', 'America/Mexico_City').toString();

          this.searchAvailabilityDate(formattedDate)
        }
      }
    }
  },
  beforeMount() {

  },
  watch: {
    mainPassengerInformation: {
      handler(newVal) {
        this.validateFirstStepForm()
      },
      deep: true // Watch for changes in nested properties
    },
  },
  mounted() {
    this.getBlockedDatesAvailability()

    OpenPay.setId('mqktdkgplkkhseztlbkw');
    OpenPay.setApiKey('pk_b519827b440e4ab38e0edda77ff2771f');
    OpenPay.setSandboxMode(true); // Asegúrate de cambiar a false en producción

    /*this.currentStep = 2
    this.goToStep(2)*/
  },
  methods: {
    // Format card holder name (capitalize words)
    formatCardHolderName() {
      // Convert all characters to uppercase and remove any invalid characters
      this.paymentData.cardHolderName = this.paymentData.cardHolderName.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
    },

    // Format card number with spaces every 4 digits
    formatCardNumber() {
      let value = this.formattedCardNumber.replace(/\D/g, ''); // Remove non-digits
      if (value.length > 16) value = value.substring(0, 16); // Limit to 16 digits
      this.formattedCardNumber = value.replace(/(.{4})/g, '$1 ').trim(); // Add space every 4 digits
      this.paymentData.cardNumber = value; // Store plain number without spaces for Openpay
    },

    // Format expiration date as MM/YY and validate
    formatExpirationDate() {
      // Eliminar cualquier carácter no numérico
      let value = this.paymentData.expirationDate.replace(/\D/g, '');

      // Si la longitud es mayor a 4 dígitos, cortarla
      if (value.length > 4) {
        value = value.substring(0, 4);
      }

      // Formatear la fecha como MM/YY si es válida
      if (value.length >= 2) {
        let month = parseInt(value.substring(0, 2), 10);
        let year = parseInt(value.substring(2, 4), 10);
        let currentYear = new Date().getFullYear() % 100; // Últimos 2 dígitos del año actual
        let currentMonth = new Date().getMonth() + 1; // Mes actual (1-12)

        // Validar que el mes esté entre 1 y 12
        if (month >= 1 && month <= 12) {
          // Verificar si la fecha de expiración es válida (futura)
          if (year > currentYear || (year === currentYear && month >= currentMonth)) {
            this.paymentData.expirationDate = `${value.substring(0, 2)}/${value.substring(2, 4)}`;
            return;
          }
        }
      }

      // Limpiar input si es inválido, pero NO interferir con otros campos
      this.paymentData.expirationDate = value.length === 0 ? '' : value;
    },

    // Validate CVV (3 to 4 digits)
    validateCVV() {
      this.paymentData.cvv = this.paymentData.cvv.replace(/\D/g, '').substring(0, 4); // Allow only digits, limit to 4 digits
    },
    createToken() {

      if (!this.acceptTerms) {
        alert("Debe aceptar los términos y condiciones.");
        return;
      }
      this.showSuccessPaymentCardMessage = false
      this.showErrorPaymentCardMessage = false
      this.showLoadingPaymentContainer = true
      // Extraemos los datos del formulario
      const cardData = {
        holder_name: this.paymentData.cardHolderName,
        card_number: this.paymentData.cardNumber,
        expiration_month: this.paymentData.expirationDate.split('/')[0],
        expiration_year: this.paymentData.expirationDate.split('/')[1],
        cvv2: this.paymentData.cvv
      };

      // Generamos el token con Openpay
      OpenPay.token.create(cardData, this.successCallback, this.errorCallback);
    },
    successCallback(response) {
      // Token generado con éxito
      const token_id = response.data.id;
      const device_session_id = OpenPay.deviceData.setup("payment-form", "device_session_id");

      console.log("Token generado:", token_id);

      // Aquí puedes enviar el token y device_session_id a tu backend o proceder con 3D Secure
      this.confirmPayment(token_id, device_session_id);
    },
    errorCallback(response) {
      // Manejo de errores
      this.showSuccessPaymentCardMessage = true
      this.showErrorPaymentCardMessage = false
      const error_message = response.data.description !== undefined ? response.data.description : response.message;
      //alert("Error al generar el token: " + error_message);
    },
    getBanksReferenceDoc() {

      let formData = new FormData();
      //formData.append('token_id', token);
      //formData.append('device_session_id', deviceSessionId);
      formData.append('flight_date', this.flightDate);
      formData.append('cart_amounts', JSON.stringify(this.totalsCart));
      formData.append('main_passenger_information', JSON.stringify(this.mainPassengerInformation));
      formData.append('continue_without_passengers', (this.continueWithoutPassengers) ? 1 : 0);

      this.showErrorPaymentCardMessage = false
      this.showLoadingPaymentContainer = true

      // Realizar la solicitud al backend
      this.$http.post(`/manage-services/skyballoons/process-banks-payment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((res) => {
        // Revisamos la respuesta
        if (res.data.success) {
          // Si existe una URL de redirección, redirigir a la página de 3D Secure
          const lang = localStorage.getItem('language') || 'en';
          // alert('todo bien')
          // console.log(res.data.data)
          this.$router.push({
            name: 'download-bank-reference-' + lang,
            params: {
              salesQuoteId: res.data.data.sales_quote_id,
              referenceData: res.data.data
            }
          });
          this.cleanShoppingCart()

          this.showSuccessPaymentCardMessage = true
          this.showErrorPaymentCardMessage = false
        } else {
          // Error
          this.showSuccessPaymentCardMessage = false
          this.showErrorPaymentCardMessage = true
        }


      }).finally(() => {
        setTimeout(() => {
          this.showLoadingPaymentContainer = false
        }, 1500)

      }).catch((error) => {
        setTimeout(() => {
          this.showSuccessPaymentCardMessage = false
          this.showErrorPaymentCardMessage = true
          this.showLoadingPaymentContainer = false
        }, 1500)

        console.error("Error en el proceso de pago:", error);
      });
    },
    getStoresReferenceDoc() {

      let formData = new FormData();
      //formData.append('token_id', token);
      //formData.append('device_session_id', deviceSessionId);
      formData.append('flight_date', this.flightDate);
      formData.append('cart_amounts', JSON.stringify(this.totalsCart));
      formData.append('main_passenger_information', JSON.stringify(this.mainPassengerInformation));
      formData.append('continue_without_passengers', (this.continueWithoutPassengers) ? 1 : 0);

      // Realizar la solicitud al backend
      this.$http.post(`/manage-services/skyballoons/process-stores-payment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((res) => {
        // Revisamos la respuesta
        if (res.data.success) {
          // Si existe una URL de redirección, redirigir a la página de 3D Secure
          const lang = localStorage.getItem('language') || 'en';
          // alert('todo bien')
          // console.log(res.data.data)
          this.cleanShoppingCart()
          this.$router.push({
            name: 'download-store-reference-' + lang,
            params: {
              salesQuoteId: res.data.data.sales_quote_id,
              referenceData: res.data.data
            }
          });

          this.showSuccessPaymentCardMessage = true
          this.showErrorPaymentCardMessage = false
        } else {
          // Error
          this.showSuccessPaymentCardMessage = false
          this.showErrorPaymentCardMessage = true
        }


      }).finally(() => {
        setTimeout(() => {
          this.showLoadingPaymentContainer = false
        }, 1500)
      }).catch((error) => {
        setTimeout(() => {
          this.showSuccessPaymentCardMessage = false
          this.showErrorPaymentCardMessage = true
          this.showLoadingPaymentContainer = false
        }, 1500)

        console.error("Error en el proceso de pago:", error);
      });
    },
    cleanShoppingCart() {
      // Eliminar 'items-additional-services-cart' del localStorage
      localStorage.removeItem('items-additional-services-cart');

      // Eliminar 'items-cart' del localStorage
      localStorage.removeItem('items-cart');
    },
    confirmPayment(token, deviceSessionId) {
      let formData = new FormData();
      formData.append('token_id', token);
      formData.append('device_session_id', deviceSessionId);
      formData.append('flight_date', this.flightDate);
      formData.append('cart_amounts', JSON.stringify(this.totalsCart));
      formData.append('main_passenger_information', JSON.stringify(this.mainPassengerInformation));
      formData.append('continue_without_passengers', (this.continueWithoutPassengers) ? 1 : 0);

      // Realizar la solicitud al backend
      this.$http.post(`/manage-services/skyballoons/process-card-payment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((res) => {
        // Revisamos la respuesta
        if (res.data.redirect_url) {
          // Si existe una URL de redirección, redirigir a la página de 3D Secure
          window.location.href = res.data.redirect_url;
        } else {
          // Si no hay redirección, el pago fue procesado sin 3D Secure
          const lang = localStorage.getItem('language') || 'en';
          this.cleanShoppingCart()
          this.$router.push({
            name: 'payment-success-' + lang,
            params: {
              salesQuoteId: res.data.data.sales_quote_id,
              invoicePaymentId: res.data.data.payment_id
            }
          });
        }

        this.showSuccessPaymentCardMessage = true
        this.showErrorPaymentCardMessage = false
      }).finally(() => {
        setTimeout(() => {
          this.showLoadingPaymentContainer = false
        }, 1500)
      }).catch((error) => {
        setTimeout(() => {
          this.showSuccessPaymentCardMessage = false
          this.showErrorPaymentCardMessage = true
        }, 1500)

        console.error("Error en el proceso de pago:", error);
      });
    },
    occupyMainPassengerName() {
      let mainFullName = `${this.mainPassengerInformation.full_name} ${this.mainPassengerInformation.lastname}`

      this.$set(this.finalCart.flight.passengers[0].passenger_details[0], 'full_name', mainFullName);
      this.$forceUpdate(); // Force update
      this.validateFirstStepForm()

    },
    buildCartFromSalesQuoteService(salesQuoteInformation = []) {
      // console.log(salesQuoteInformation)

      localStorage.setItem('items-additional-services-cart', JSON.stringify(salesQuoteInformation.additional_services));

      localStorage.setItem('items-cart', JSON.stringify(salesQuoteInformation.flight_sales_quote))

      // BUild card
      this.getShoppingCartItems()

      // Alter flight quantities
      salesQuoteInformation.flight_prices_sales_quote.forEach((item, index) => {
        // search flight price id and alter quantities
        this.finalCart.flight.passengers.forEach((itemPassenger, indexPassenger) => {
          if (parseInt(itemPassenger.flight_price_id) === parseInt(item.flight_price_id)) {
            itemPassenger['quantities'] = itemPassenger.quantities
          }
        })
      })

      this.getAdditionalServices()
      this.setCalendarAttributes(new Date());

      // Setting flight date
      this.flightDate = salesQuoteInformation.sales_quote.flight_date_sales_quote
      const formattedDate = moment.tz(this.flightDate, 'YYYY-MM-DD', 'America/Mexico_City').toString();

      this.searchAvailabilityDate(formattedDate)

      // Apply coupons
      if (salesQuoteInformation.sales_quote.has_discount_coupon_applied && parseInt(salesQuoteInformation.sales_quote.has_discount_coupon_applied) > 0) {
        this.discountCouponText = salesQuoteInformation.discount_coupon_applied.discount_coupon_details
        this.discountCouponApplied = salesQuoteInformation.discount_coupon_applied
        this.getTotalsCart()
      }

    },
    getSalesQuoteBySalesQuoteId(salesQuoteId) {
      //manage-services/get-sales-quote-by-sales-quote-id
      this.$http.post(`/manage-services/get-sales-quote-by-sales-quote-id`, {
        sales_quote_id: salesQuoteId
      }).then((res) => {
        // here the information quote is loaded to the cart
        this.buildCartFromSalesQuoteService(res.data.data)
      })
    },
    flightsRedirect() {
      const lang = localStorage.getItem('language') || 'en';

      this.$router.push({name: 'flights-' + lang});
    },
    setCalendarAttributes(visibleMonth) {
      // Obtener el primer y último día del mes visible
      const startOfMonth = moment.tz(visibleMonth, 'America/Mexico_City').startOf('month').toDate();
      const endOfMonth = moment.tz(visibleMonth, 'America/Mexico_City').endOf('month').toDate();

      // Convertir las fechas de disponibilidad
      const lowAvailabilityDates = this.dates_availability.low_availability.map(date => moment.tz(date, 'America/Mexico_City').format('YYYY-MM-DD'));
      const mediumAvailabilityDates = this.dates_availability.medium_availability
          .filter(date => !lowAvailabilityDates.includes(moment.tz(date, 'America/Mexico_City').format('YYYY-MM-DD')))
          .map(date => moment.tz(date, 'America/Mexico_City').format('YYYY-MM-DD'));

      // Atributos para dots rojos
      const lowAvailabilityAttribute = {
        dates: lowAvailabilityDates.map(date => moment.tz(date, 'America/Mexico_City').toDate()),
        dot: {color: 'red'},
      };

      // Atributos para dots amarillos
      const mediumAvailabilityAttribute = {
        dates: mediumAvailabilityDates.map(date => moment.tz(date, 'America/Mexico_City').toDate()),
        dot: {color: 'yellow'},
      };

      // Atributos para dots verdes por defecto
      const defaultAttribute = {
        dates: {start: startOfMonth, end: endOfMonth},
        excludeDates: [...lowAvailabilityDates, ...mediumAvailabilityDates].map(date => moment.tz(date, 'America/Mexico_City').toDate()), // Excluir las fechas con dots específicos
        dot: {color: 'green'},
      };

      this.calendarAttributes = [lowAvailabilityAttribute, mediumAvailabilityAttribute, defaultAttribute];
    },
    changeFlightDate() {

      if (this.discountCouponApplied) {
        Swal.fire({
          title: 'Atención',
          text: "Si cambia su fecha de vuelo deberá deberá volver a validar su código de descuento",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, deseo cambiarla',
          cancelButtonText: 'No, cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close()
            this.flightDate = null
            this.allowToApplyCoupons = false
            this.discountCouponText = ''
            this.discountCouponApplied = null
            this.getTotalsCart()
          }
        });
      } else {
        this.flightDate = null
        this.allowToApplyCoupons = false
      }

    },
    formatToFriendlyDate(date) {
      // Convertir la fecha de string a un objeto moment
      // Configurar Moment.js con el locale deseado
      moment.locale('es');

      // Convertir la fecha de string a un objeto moment
      const formattedDate = moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');

      // Capitalizar la primera letra del mes
      return this.capitalizeMonth(formattedDate);
    },
    capitalizeMonth(dateString) {
      // Capitaliza la primera letra del mes
      return dateString.replace(/^(.)/, (match, p1) => p1.toUpperCase());
    },
    getBlockedDatesAvailability() {
      this.$http.get(`/manage-logistic/get-blocked-dates-availability`)
          .then((res) => {
            this.listDatesDisabledAvailability = res.data
            this.datesDisabled = []
            for (let dateBlocked = 0; dateBlocked < this.listDatesDisabledAvailability.length; dateBlocked++) {
              this.datesDisabled.push({
                start: moment(this.listDatesDisabledAvailability[dateBlocked].date).toDate(),
                end: moment(this.listDatesDisabledAvailability[dateBlocked].date).toDate()
              })
            }
          })
    },
    validateDiscountCoupon() {
      if (this.discountCouponText.trim().length <= 0 ||
          !this.flightDate) {
        Swal.fire({
          icon: 'error',
          title: 'Código inválido',
          text: 'El código ingresado es inválido.',
          confirmButtonText: 'Intentar de nuevo'
        });
        return false
      }

      this.$http.post(`/manage-services/validate-discount-coupon`, {
        discount_coupon: this.discountCouponText,
        flight_id: this.finalCart.flight.flight_id,
        flight_date: this.flightDate
      })
          .then((res) => {
            if(res.data.status) {
              Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                html: `El código promocional <strong>${this.discountCouponText}</strong> se ha aplicado correctamente al carrito, favor de verificarlo.`,
                confirmButtonText: 'Aceptar'
              });

              this.discountCouponApplied = res.data.data
              this.getTotalsCart()
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Código inválido',
                text: 'El código ingresado es inválido.',
                confirmButtonText: 'Intentar de nuevo'
              });
              this.discountCouponText = ''
            }
          }).catch((e) => {
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Código inválido',
          text: 'El código ingresado es inválido.',
          confirmButtonText: 'Intentar de nuevo'
        });
        this.discountCouponText = ''
        //console.log(e)
      }).finally(() => {
      })
    },
    proceedPaymentForm() {
      if (this.validateFirstStepForm()) {
        this.goToStep(2)

        window.scrollTo({top: 0, behavior: 'smooth'});
      }
      return false
    },
    updateServicesCart() {
      this.getTotalsCart()
    },
    updateServicesC() {
      // Filtrar los elementos de servicesB que no están en servicesA
      this.servicesC = this.servicesB.filter(
          serviceB => !this.servicesA.some(serviceA => serviceA.id === serviceB.id)
      );
    },
    existsInServicesA(currentItemService) {
      // Verificar si el item enviado existe en servicesA
      return this.finalCart.additionalServices.some(serviceA => parseInt(serviceA.additional_service_id) === parseInt(currentItemService.additional_service_id));
    },
    getThumbnailService(itemService) {
      if (!itemService.service_thumbnail) {
        return 'https://via.placeholder.com/100x100.png?text=Unavailable'
      }
      return itemService.service_thumbnail.path_image_small
    },
    getAdditionalServices() {
      this.$http.get(`/manage-services/get-additional-services-by-categories`)
          .then((res) => {
            this.additionalServicesByCategoryAvailable = res.data.data
          }).finally(() => {
        this.generateNewArray()
      })
    },
    generateNewArray() {
      // Generar un nuevo array basado en la lógica descrita
      //console.log('/////////////////')
      //console.log(this.additionalServicesByCategoryAvailable)
      this.additionalServicesByCategoryAvailableNew = this.additionalServicesByCategoryAvailable.map(category => {
        // Filtrar items que no están en additionalServices
        const filteredItems = category.items_services.filter(item => {
          return !this.finalCart.additionalServices.some(service => parseInt(service.additional_service_id) === parseInt(item.additional_service_id));
        });

        const {items_services, ...rest} = category; // Extraer y excluir 'category_name_es'
        // Retornar la nueva estructura con items filtrados
        return {
          ...rest,
          items_services: filteredItems
        };
      });
    },
    updatePassengersCart(itemPrice) {
      // new quantities
      let newQuantities = parseInt(itemPrice.quantities)
      let newPassengers = []
      if (newQuantities > 0) {
        for (let i = 0; i < newQuantities; i++) {
          newPassengers.push({
            full_name: '',
            estimated_weight: 0,
          })
        }
        itemPrice['passenger_details'] = newPassengers
      }

      this.getTotalsCart()
    },
    getSubtotalItemServiceCart(itemService) {
      let subtotalItemService = (parseInt(itemService.quantities) * parseFloat(itemService.additional_service_price_mxn))

      return this.getFrontendPrice(subtotalItemService)
    },
    getFrontendPrice(price = '') {
      return `$${this.$number_format(this.convertPriceToFloat(price), 0, '', ',')}`
    },
    getFrontendOnlyPrice(price = '') {
      // Convertir el valor a número para asegurarse de que funciona con cantidades numéricas
      const numericPrice = parseFloat(price);

      // Verificar si el precio tiene decimales
      if (!Number.isInteger(numericPrice)) {
        // Si tiene decimales, formatear con dos decimales
        return this.$number_format(numericPrice, 2, '.', ',');
      }

      // Si no tiene decimales, formatear sin decimales
      return this.$number_format(numericPrice, 0, '', ',');
    },
    buildFirstCart() {
    },
    getFormattedPrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_price, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <small>p/${mainPrice.unit_name}</small>`
    },
    preRemoveAddSerFromCart(itemService) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción no se puede deshacer.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, estoy seguro',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          // Espacio para tomar una decisión cuando el usuario acepta
          // Por ejemplo, puedes llamar a un método para realizar una acción
          //console.log('Usuario confirmó la acción.');
          this.removeAddSerFromCart(itemService)
          // Tu código aquí para cuando el usuario acepta
        } else {
          // El usuario canceló la acción
          //console.log('Usuario canceló la acción.');
          // No se necesita código aquí para cancelar, SweetAlert se cierra automáticamente
        }
      });
    },
    removeAddSerFromCart(itemService) {
      // Obtener los elementos del localStorage
      let itemsAdditionalServicesCart = JSON.parse(localStorage.getItem('items-additional-services-cart')) || [];

      // Filtrar el array para eliminar el elemento que coincida con el id de itemService
      itemsAdditionalServicesCart = itemsAdditionalServicesCart.filter(service => parseInt(service.additional_service_id) !== parseInt(itemService.additional_service_id));

      // Guardar el array actualizado en el localStorage
      localStorage.setItem('items-additional-services-cart', JSON.stringify(itemsAdditionalServicesCart));

      // Actualizar la variable en Vue
      this.finalCart.additionalServices = JSON.parse(localStorage.getItem('items-additional-services-cart')) || [];

      // Ejecutar el método generateNewArray() después de la actualización
      this.generateNewArray()
      this.getTotalsCart()
      // Mostrar un mensaje de éxito
      Swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El servicio ha sido eliminado correctamente del carrito.',
        confirmButtonText: 'Aceptar'
      });
    },
    addAddSerToCart(itemService) {
      // Obtener los elementos del localStorage
      let itemsAdditionalServicesCart = JSON.parse(localStorage.getItem('items-additional-services-cart')) || [];

      // Verificar si el item ya existe en el array
      const exists = itemsAdditionalServicesCart.some(service => parseInt(service.additional_service_id) === parseInt(itemService.additional_service_id));

      // Si no existe, añadirlo al array
      if (!exists) {
        itemService['quantities'] = 1
        itemsAdditionalServicesCart.push(itemService);

        // Guardar el array actualizado en el localStorage
        localStorage.setItem('items-additional-services-cart', JSON.stringify(itemsAdditionalServicesCart));
      } else {
        //console.log('El servicio ya está en el carrito');
      }

      // Para depuración, puedes imprimir el contenido actualizado del localStorage
      //console.log(localStorage.getItem('items-additional-services-cart'));

      this.finalCart.additionalServices = JSON.parse(localStorage.getItem('items-additional-services-cart')) || []
      //this.getShoppingCartItems()
      this.generateNewArray()
      this.getTotalsCart()
      Swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'La operación se realizó correctamente.',
        confirmButtonText: 'Aceptar'
      });

    },
    getFrontendDiscountString(discountCouponApplied) {
      let discountFinalString = '',
          discountAmount = 0
      if (parseInt(this.discountCouponApplied.discount_coupon_type) === 2) {
        // se hace la resta de la cantidad del descuento al subtotal
        discountAmount = parseFloat(discountCouponApplied.discount_coupon_amount);
        discountFinalString = `-$${(discountAmount.toFixed(2))}`
      }
      // porcentaje
      else {
        let discountFinalAmount = (parseFloat(this.totalsCart.subtotal_global_services) * parseFloat(discountCouponApplied.discount_coupon_amount)) / 100
        discountAmount = discountFinalAmount
        discountFinalString = `-$${(discountAmount.toFixed(2))} (${this.getFrontendOnlyPrice(discountCouponApplied.discount_coupon_amount)}%)`
        // parseInt(this.discountCouponApplied.discount_coupon_type)
      }

      return discountFinalString
    },
    getTotalsCart() {
      let subtotal_flight = 0,
          subtotal_services = 0

      this.totalsCart = {
        flight: {
          details: {},
          passengers: [],
          subtotal_flight: 0,
        },
        services: [],
        subtotal_flight: 0,
        subtotal_services: 0,
        charges: 0,
        discounts: 0,
        total_global: 0,
        discount_coupon_applied: []
      }


      subtotal_flight = 0
      // get subtotals per flight
      if (this.finalCart.flight) {
        this.totalsCart.flight.details = this.finalCart.flight

        // read passengers
        this.finalCart.flight.passengers.map((passenger) => {
          if (parseInt(passenger.quantities) > 0) {
            let subtotalPassenger = parseFloat(passenger.price_per_item) * passenger.quantities
            subtotal_flight += subtotalPassenger

            this.totalsCart.flight.passengers.push({
              quantities: passenger.quantities,
              price_per_item: parseFloat(passenger.price_per_item),
              subtotal: subtotalPassenger,
              flight_price_id: passenger.flight_price_id,
              unit_id: passenger.unit_id,
              unit_name: passenger.unit_name,
              unit_real_name_en: passenger.unit_real_name_en,
              unit_real_name_es: passenger.unit_real_name_es,
              number_of_units: passenger.number_of_units,
            })
          }
        })

        this.totalsCart.flight.subtotal_flight = subtotal_flight

      }


      subtotal_services = 0
      if (this.finalCart.additionalServices && this.finalCart.additionalServices.length > 0) {
        this.finalCart.additionalServices.map((service) => {
          //console.log(service)
          let subtotalItem = parseFloat(service.additional_service_price_mxn) * parseInt(service.quantities)
          subtotal_services += subtotalItem

          this.totalsCart.services.push({
            additional_service_id: service.additional_service_id,
            quantities: service.quantities,
            price_per_item: parseFloat(service.additional_service_price_mxn),
            subtotal: subtotalItem,
            additional_service_description_es: service.additional_service_description_es,
            additional_service_description_en: service.additional_service_description_en,
            unit_id: service.unit_id,
            unit_name: service.unit_long_name_es,
            unit_real_name_en: service.unit_short_name_es,
            unit_real_name_es: service.unit_short_name_us,
          })
        })
      }

      // console.log('totalsCart--->')
      // console.log(this.totalsCart)

      this.totalsCart.charges = 0
      this.totalsCart.subtotal_flight = subtotal_flight
      this.totalsCart.subtotal_services = subtotal_services
      this.totalsCart.subtotal_global_services = this.totalsCart.subtotal_flight + this.totalsCart.subtotal_services
      this.totalsCart.amount_discounts = 0
      this.totalsCart.discount_coupon_applied = []

      //this.totalsCart.total_global = this.totalsCart.subtotal_flight + this.totalsCart.subtotal_services

      //this.totalsCart.discounts = null
      console.log(this.discountCouponApplied)
      if (this.discountCouponApplied) {
        //consle.log('discount applied')
        //console.log(this.discountCouponApplied)
        this.totalsCart.discount_coupon_applied = this.discountCouponApplied
        // cantidad
        if (parseInt(this.discountCouponApplied.discount_coupon_type) === 2) {
          // se hace la resta de la cantidad del descuento al subtotal
          this.totalsCart.amount_discounts = parseFloat(this.discountCouponApplied.discount_coupon_amount);
        }
        // porcentaje
        else {
          let discountFinalAmount = (parseFloat(this.totalsCart.subtotal_global_services) * parseFloat(this.discountCouponApplied.discount_coupon_amount)) / 100
          this.totalsCart.amount_discounts = discountFinalAmount
          // parseInt(this.discountCouponApplied.discount_coupon_type)
        }
      }

      this.totalsCart.total_global = ((this.totalsCart.subtotal_global_services) - (this.totalsCart.amount_discounts)).toFixed(2)
      // Al final sumamos cargos
      this.totalsCart.total_global = (parseFloat(this.totalsCart.total_global) + parseFloat(this.totalsCart.charges)).toFixed(2)

      this.validateFirstStepForm()

      return this.totalsCart
      //console.log(this.totalsCart)
      // get subtotals per additional services
    },
    buildShoppingCartItems() {
      this.finalCart = {
        flight: {
          passengers: [],
        },
        additionalServices: [],
      }

      this.finalCart.flight = JSON.parse(localStorage.getItem('items-cart')) || {}
      this.finalCart.additionalServices = JSON.parse(localStorage.getItem('items-additional-services-cart')) || []


      // Build first item flight cart
      // Check if there were passengers already set

      this.finalCart.flight['passengers'] = []
      this.finalCart.flight['passengers'].push({
        main_passenger: 1,
        subtotal: this.convertPriceToFloat(this.flightCart.flight_after_discount_price.flight_price) * 1,
        quantities: 1,
        price_per_item: this.convertPriceToFloat(this.flightCart.flight_after_discount_price.flight_price),
        flight_price_id: this.flightCart.flight_after_discount_price.flight_price_id,
        unit_name: this.flightCart.flight_after_discount_price.unit_name,
        unit_real_name_es: this.flightCart.flight_after_discount_price.unit_real_name_es,
        unit_real_name_en: this.flightCart.flight_after_discount_price.unit_real_name_en,
        unit_id: this.flightCart.flight_after_discount_price.unit_id,
        number_of_units: this.flightCart.flight_after_discount_price.number_of_units,
        passenger_details: [{
          full_name: '',
          estimated_weight: 0,
        }],
      })

      // additional optional passengers
      if (this.flightCart.flight_additional_prices && this.flightCart.flight_additional_prices.length > 0) {
        this.flightCart.flight_additional_prices.forEach((item, index) => {
          this.finalCart.flight['passengers'].push({
            main_passenger: 0,
            subtotal: this.convertPriceToFloat(item.flight_price) * 1,
            quantities: 0,
            price_per_item: this.convertPriceToFloat(item.flight_price),
            flight_price_id: item.flight_price_id,
            unit_name: item.unit_name,
            unit_real_name_es: item.unit_real_name_es,
            unit_real_name_en: item.unit_real_name_en,
            unit_id: item.unit_id,
            passenger_details: [],
            number_of_units: item.number_of_units,
          })
        })
      }

      this.getTotalsCart()
    },
    getShoppingCartItems() {
      // get flight cart
      this.flightCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // get additional services cart
      this.additionalServicesCart = JSON.parse(localStorage.getItem('items-additional-services-cart')) || []

      //console.log(this.flightCart)

      // BUild card
      this.buildShoppingCartItems()

      //console.log('final checkout cart')
      //console.log(this.finalCart)
    },
    getNumberPassengers() {
      return this.totalsCart.flight.passengers.reduce((a, b) => a + parseInt(b.number_of_units), 0)
    },
    isDateAvailableToBook(date) {
      return this.datesDisabled.some(disabledRange => {
        const startDate = new Date(disabledRange.start);
        const endDate = new Date(disabledRange.end);

        // Comparar la fecha seleccionada con los rangos deshabilitados
        return date >= startDate && date <= endDate;
      });

    },
    searchAvailabilityDate({date}) {
      this.loadingSearchingFlightDate = true
      this.allowToApplyCoupons = false
      this.isFlightDateAvailableToBook = false
      if (this.isDateAvailableToBook(date)) {
        Swal.fire({
          icon: 'error',
          title: 'Fecha no disponible',
          text: 'La fecha seleccionada está deshabilitada porque no hay disponibilidad de vuelo para esta fecha',
          confirmButtonText: 'Intentar de nuevo'
        });
        this.flightDate = null
        this.loadingSearchingFlightDate = false
        //console.log('Fecha deshabilitada seleccionada:', date);
        return; // No hacer nada si la fecha está deshabilitada
      }

      this.$http.post(`/manage-logistic/get-status-availability-by-flight-date`, {
        flight_date: this.flightDate,
        category_flight_id: parseInt(this.finalCart.flight.flight_category_id),
        number_pax_to_book: this.getNumberPassengers(),
      })
          .then((res) => {
            /* this.detailsDateAvailability = res.data
             try {
               this.isDateAvailableToBook = this.detailsDateAvailability.is_date_available_to_book
             } catch(e) {
               //console.log(e)
             }*/

            if (res.data.is_date_available_to_book === 1) {
              this.allowToApplyCoupons = true
              this.isFlightDateAvailableToBook = true
            } else {
              this.flightDate = null

              Swal.fire({
                icon: 'error',
                title: 'Fecha no disponible',
                text: 'No se encontró disponibilidad para esta fecha de vuelo, por favor elija una diferente',
                confirmButtonText: 'Intentar de nuevo'
              });
            }

          }).finally(() => {
        this.validateFirstStepForm()
        this.loadingSearchingFlightDate = false
        /*this.loadingSearchingAvailabilityDate = false
        this.showAvailabilityDate = true
        this.getBlockedDatesAvailability()
        // Invaldating discount coupons in case there is no availability to fly on this new date
        if (!this.detailsDateAvailability.isDateAvailableToBook) {
          this.rollbackDiscountCoupon()
        }*/
      })
    },
    convertPriceToFloat(price = '') {
      return parseFloat(price).toFixed(2)
    },
    userCanProceedToPayment() {

      this.isStep1Complete = true
    },
    goToStep: function (step) {
      if (step === 2 && !this.isStep1Complete) return;
      this.currentStep = step;
    },
    testvalidateFirstStepForm() {
      console.log(this.validateFirstStepForm())
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email); // Returns true if the email is valid
    },
    isValidPhoneNumber(phone) {
      // Must start with '+', followed by 1 to 3 digits for the area code, and at least 10 digits for the phone number
      const phonePattern = /^\+\d{1,3}\d{10,}$/;
      return phonePattern.test(phone); // Returns true if the phone number is valid
    },
    validateFirstStepForm: function () {
      this.isStep1Complete = false;
      this.canProceedPaymentForm = false;
      //this.currentStep = 1;

      if (
          !this.flightDate ||
          !this.isFlightDateAvailableToBook
      ) {
        console.log('Flight Date is not valid')
        return false;
      }

      if (
          this.mainPassengerInformation.full_name.trim() <= 0 ||
          this.mainPassengerInformation.lastname.trim() <= 0 ||
          this.mainPassengerInformation.email.trim() <= 0 ||
          this.mainPassengerInformation.email_confirmed.trim() <= 0 ||
          this.mainPassengerInformation.phone_contact.trim() <= 0
      ) {
        console.log('Missing main passenger information')
        return false;
      }

      // Validar que los correos sean válidos
      if (!this.isValidEmail(this.mainPassengerInformation.email) || !this.isValidEmail(this.mainPassengerInformation.email_confirmed)) {
        console.log('Invalid email format');
        return false;
      }

      // Validar que los correos sean idénticos
      if (this.mainPassengerInformation.email !== this.mainPassengerInformation.email_confirmed) {
        console.log('Emails do not match');
        return false;
      }

      // Validar el número de teléfono
      if (!this.isValidPhoneNumber(this.mainPassengerInformation.phone_contact)) {
        console.log('Invalid phone number');
        return false;
      }

      let passengersSalesQuote = this.validatePassengers()
      if (
          !passengersSalesQuote.success
      ) {
        console.log('Missing extra passengers information')
        return false;
      }

      this.canProceedPaymentForm = true
      this.isStep1Complete = true;
      //this.currentStep = 2;
      return true

    },
    testValidatePassengers() {
      console.log(this.validatePassengers())
    },
    validatePassengers() {
      // Access the array finalCart.flight.passengers
      if (this.continueWithoutPassengers) {
        return {
          success: true,
          errors: ""
        };
      }

      const passengers = this.finalCart.flight.passengers;

      // Loop through each passenger in the array
      for (let i = 0; i < passengers.length; i++) {
        const passengerDetails = passengers[i].passenger_details;

        // Loop through each passenger detail in the passenger_details array
        for (let j = 0; j < passengerDetails.length; j++) {
          const passenger = passengerDetails[j];

          // Validate that full_name is not empty and estimated_weight is greater than 0
          if (!passenger.full_name || passenger.estimated_weight <= 0) {
            // If validation fails, return an object with success set to false and an error message
            return {
              success: false,
              errors: "Algunos campos en el área de pasajeros no se han completado, favor de revisarlos."
            };
          }
        }
      }

      // If all fields are valid, return an object with success set to true
      return {
        success: true,
        errors: ""
      };
    }
  },

}
</script>

<style scoped>
</style>