<template>
  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded" :class="`${customClass}`">

    <div class="row">
      <div class="col-lg-3">

      </div>
      <div class="col-lg-9">
        <h4 class="card-title text-dark" :title="itemService.additional_service_description_es">
          <strong>{{ (itemService.additional_service_description_es) }}</strong>
        </h4>


        <hr>
        <button class="btn btn-primary btn-lg me-3">
          <i class="bi bi-cart-plus"></i> {{ $t('buttons.add_now') }}
        </button>
        <button @click="openDetailsAddSer(itemService)" class="btn btn-light btn-lg me-3">
          <i class="bi bi-arrow-up-right"></i> {{ $t('buttons.view_more') }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AServiceCardCart',
  props: ['itemService', 'customClass', 'fromHome'],
  methods: {
    openDetailsAddSer(itemService) {
      this.$emit('service-details-opened', {
        is_opened: true,
        item_service: itemService
      })
    },
    getFormattedPrice(servicePrice) {
      let priceFormatted = this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',');

      return `$${priceFormatted} p/${servicePrice.unit_long_name_es}`
    },
    abbreviateText(text) {
      if (this.fromHome && this.fromHome !== false) {
        return text;
      }

      const maxLength = 10;
      if (text.length > maxLength) {
        return text.slice(0, maxLength).trim() + '...';
      }
      return text;
    },
    getThumbnailService(itemService) {
      if (!itemService.service_thumbnail) {
        return 'https://via.placeholder.com/500x500.png?text=Unavailable'
      }
      return itemService.service_thumbnail.path_image_small
    }
  }
}
</script>

<style scoped>

</style>
