<template>
  <div class="payment-icons mt-4">
    <img class="me-2" src="https://img.icons8.com/?size=30&id=1429&format=png&color=000000" alt="Visa">
    <img class="me-2" src="https://img.icons8.com/?size=30&id=11080&format=png&color=000000" alt="Mastercard">
    <img class="me-2" src="https://img.icons8.com/?size=30&id=24579&format=png&color=000000" alt="Paypal">
    <img class="me-2" src="https://img.icons8.com/?size=30&id=16026&format=png&color=000000" alt="Transferencias bancarias">
    <img class="me-2" src="https://img.icons8.com/?size=30&id=76949&format=png&color=000000" alt="Pagos en efectivo">
    <p class="p-0 m-0">
      <small>* Aceptamos pagos con tarjeta, Paypal, transferencias bancarias, pagos en tiendas de convenciencia, y más.
        <a href="#payment-methods-section">Conoce nuestras formas de pago</a></small>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PaymentIcons'
}
</script>

<style scoped>
</style>