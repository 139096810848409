import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    en: {
        menu_bar: {
            home_item_text: 'Home',
            flights_text: 'Flights',
            additional_services_text: 'Additional Services',
            more_about_us_text: 'About us',
            shopping_cart_text: 'Cart'
        },
        quick_bar: {
            select_flight_title: 'Select flight',
            placeholder_select_flight: 'Looking for our specials?'
        },
        home_section: {
            additional_services_title: 'Discover our additional services for boosting your experience'
        },
        logo_site_name: 'Skyballoons Mexico',
        flights_section: {
            title_secion: 'Descubre las mejores experiencias en vuelo en globo',
            'includes_text': 'Includes',
            'buy_now': 'Buy now',
            'view_more': 'View more',
        },
        footer: {
            need_help: 'Do you need further assistance? Get in contact now!',
            payment_methods: 'Payment methods',
            job_board: 'Job board',
            privacy_notice: 'Privacy notice',
            terms_and_conditions: 'Terms and conditions',
            billing: 'Billing',
            agency_text: 'I am an agency',
            concierge_text: 'I am a concierge',
            group_text: 'I have a group',
            corporative_services_text: 'Corporate services',
            relations_text: 'Media and Public Relations',
            publicity_text: 'Hot air balloon advertising',
            festivals_text: 'Festivals and Others',
            signin_text: 'Sign in',
            signup_text: 'Sign up',
            information_footer: 'All your transactions on this site are protected by our SSL security certificate with a secure 256-bit connection.',
            information_copy: 'Sky Balloons México | © Copyright Sky Balloons Mx 2022 | Carr. México - Tulancingo Km 28.0 Int. 1, Francisco Villa, 55830 San Martín Centro Méx.',
            secure_payment_methods: 'Secure payment methods',
            agencies_and_concierges: 'Agencies and Concierges',
            follow_us_on_social_media: 'Follow us on our social media',
            my_skyballoons: 'Mi SkyBalloons',
            fly_higher: 'Fly higher',
            more_information: 'More information',
        },
        offers_text: 'Discover the best offers',
        additional_services_text: 'Conquer the sky with our additional services',
        buttons: {
          view_more: 'View more',
        },
        ready_for_booking: 'Ready for booking?',
        welcome: 'Welcome',
        home: 'Home',
        about: 'About',
        // Otros textos
    },
    es: {
        menu_bar: {
            home_item_text: 'Home',
            flights_text: 'Vuelos en Globo',
            additional_services_text: 'Servicios Adicionales',
            more_about_us_text: 'Sobre nosotros',
            shopping_cart_text: 'Carrito'
        },
        quick_bar: {
            select_flight_title: 'Seleccione su vuelo',
            placeholder_select_flight: 'Busque rápidamente nuestros mejores vuelos'
        },
        home_section: {
            additional_services_title: 'Conoce nuestros servicios adicionales'
        },
        flights_section: {
            title_secion: 'Descubre las mejores experiencias en vuelo en globo',
            'includes_text': 'Incluye',
            'buy_now': 'Compra ya',
            'view_more': 'Ver más',
        },
        logo_site_name: 'Skyballoons México',
        footer: {
            need_help: '¿Necesitas ayuda? Contáctanos ahora',
            payment_methods: 'Formas de pago',
            job_board: 'Bolsa de trabajo',
            privacy_notice: 'Aviso de privacidad',
            terms_and_conditions: 'Términos y condiciones',
            billing: 'Facturación',
            agency_text: 'Soy agencia',
            concierge_text: 'Soy concierge',
            group_text: 'Tengo un grupo',
            corporative_services_text: 'Servicios corporativos',
            relations_text: 'Medios y Relaciones Públicas',
            publicity_text: 'Publicidad en globo aerostático',
            festivals_text: 'Festivales y Otros',
            signin_text: 'Iniciar sesión',
            signup_text: 'Registrarse',
            information_footer: 'Todas tus transacciones en este sitio están protegidas por nuestro certificado de seguridad SSL con conexión segura de 256 bits.',
            information_copy: 'Sky Balloons México | © Copyright Sky Balloons Mx 2022 | Carr. México - Tulancingo Km 28.0 Int. 1, Francisco Villa, 55830 San Martín Centro Méx.',
            secure_payment_methods: 'Métodos de pago seguros',
            agencies_and_concierges: 'Agencias y Concierges',
            follow_us_on_social_media: 'Síguenos en nuestras redes sociales',
            my_skyballoons: 'Mi SkyBalloons',
            fly_higher: 'Vuela más alto',
            more_information: 'Más información'
        },
        offers_text: 'Descubre las mejores ofertas',
        additional_services_text: 'Conquista el cielo con nuestros servicios adicionales',
        buttons: {
            view_more: 'Ver más',
            buy_now: 'Comprar ahora',
            add_now: 'Añadir ahora'
        },
        ready_for_booking: '¿Listo para reservar?',
        welcome: 'Bienvenido',
        home: 'Inicio',
        about: 'Acerca de',
        // Otros textos
    }
};

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'en', // Idioma por defecto basado en localStorage
    messages
});

export default i18n;
