<template>
  <div>
    <div class="row">
      <div v-for="itemInclude in itemFlight.flight_includes" class="col-lg-6 col-md-12">
        <div class="service-card shadow-sm mb-4">
          <i :class="`item-include ${itemInclude.custom_web_icon}`"></i> {{ itemInclude.additional_item_name_es }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlightsItemsInclude',
  props: ['itemFlight'],
}
</script>

<style scoped>
</style>