<template>
  <div>
    <div class="">
      <div class="custom-container">
        <a class="custom-link" href="#">
          <div class="custom-column ">
            <div class="column-content">
              <div class="icon">
                <img :src="getIconUrl('seguro')" alt="">
              </div>
              <div class="text-content">
                <div class="line1">Seguro de</div>
                <div class="line2">Viajero</div>
              </div>
            </div>
          </div>
        </a>
        <a class="custom-link" href="#">
          <div class="custom-column">
            <div class="column-content">
              <div class="icon">
                <img :src="getIconUrl('certificate')" alt="">
              </div>
              <div class="text-content">
                <div class="line1">Certificado de</div>
                <div class="line2">Vuelo</div>
              </div>
            </div>
          </div>
        </a>
        <a class="custom-link" href="#">
          <div class="custom-column">
            <div class="column-content">
              <div class="icon">
                <img :src="getIconUrl('breakfast')" alt="">
              </div>
              <div class="text-content">
                <div class="line1">Desayuno</div>
                <div class="line2">Buffete</div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <template v-if="bannersImagesHome && bannersImagesHome.length > 0">
        <VueSlickCarousel ref="slick" v-bind="slickOptions" class="home-carousel">
          <div
              :alt="image.alt_description"
              @click="redirectActionBanner(image)"
              v-for="(image, index) in bannersImagesHome"
              :style="{
                backgroundImage: `url(${getBannerUrl(image)})`
               }"
              :key="index" class="item-slider px-2"></div>
        </VueSlickCarousel>
      </template>
    </div>






  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import AServiceCard from "@/views/templates/AServiceCard.vue";
import AServiceCardHome from "@/views/templates/AServiceCardHome.vue";

export default {
  name: 'RightHomeView',
  components: {
    AServiceCardHome,
    VueSlickCarousel,
    AServiceCard
  },
  data() {
    return {
      bannersImagesHome: [],

      images: [
        'https://picsum.photos/1000/400',
        'https://picsum.photos/1000/400',
        'https://picsum.photos/1000/400',
      ],
      slickOptions: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        dots: true,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
      },
      additionalServices: null,
      slickOptionsServices: {
        infinite: true,
        slidesToShow: 4, // Número de elementos en pantalla grande
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
        responsive: [
          {
            breakpoint: 1024, // Para pantallas menores a 1024px (tableta y móviles)
            settings: {
              slidesToShow: 3, // Número de elementos en pantallas de tableta
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768, // Para pantallas menores a 768px (móviles)
            settings: {
              slidesToShow: 1, // Número de elementos en móviles
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getBanners()
  },
  methods: {
    getBannerUrl(itemBanner){
      return `${process.env.VUE_APP_API_URL}${itemBanner.path_banner_desktop}`
    },
    getIconUrl(iconName){
      return `${process.env.VUE_APP_API_URL}assets/images/flight-icons/${iconName}.png`
    },
    getBanners() {
      //
      this.$http.get(`/manage-services/get-banners/1`)
          .then((res) => {
            this.bannersImagesHome = res.data.data

          }).finally(() => {
      })
    },
    redirectActionBanner(itemBanner){
      // handle banner click for redirect purposes
      console.log(itemBanner)
      if(itemBanner.custom_link) {
        let redirectUrl = itemBanner.custom_link
        window.location.href = redirectUrl
      }
    },



  }
}
</script>
<style>
.home-carousel {
  cursor: pointer;
}
.additional-services-carousel-home .slick-dots,
.home-carousel .slick-dots {
  position: absolute;
  bottom: -1em;
}

.additional-services-carousel-home .slick-arrow,
.home-carousel .slick-arrow {
  z-index: 999;
  background-color: #ffffff8f;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}

.additional-services-carousel-home .slick-arrow.slick-prev,
.home-carousel .slick-arrow.slick-prev {
  left: 20px;
}

.additional-services-carousel-home .slick-arrow.slick-next,
.home-carousel .slick-arrow.slick-next {
  right: 25px;
}

.additional-services-carousel-home .slick-prev::before,
.home-carousel .slick-prev::before {
  color: #fff !important;
  content: "keyboard_backspace";
  font-family: "Material Icons";
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.additional-services-carousel-home .slick-next::before,
.home-carousel .slick-next::before {
  color: #fff !important;
  content: "trending_flat";
  font-family: "Material Icons";
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}


.item-slider {
  width: 100% !important;
  height: 400px;
  background-position: center;
  background-size: cover;
}
.home-carousel .slick-slide {
  display: inline-block !important;;
}
.custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.custom-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-column:not(:last-child) .column-content {
  border-right: 1px solid #a5a5a5;
}


.column-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.icon {

  font-size: 2rem; /* Tamaño del ícono */
  margin-right: 15px; /* Espacio entre el ícono y el texto */
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.line1 {
  font-weight: normal;
  text-align: left;
}

.line2 {
  font-weight: bold;
  text-align: left;
}

.line1,
.line2,
.icon {
  color: #555;
}

.custom-link {
  text-decoration: none; /* Elimina el subrayado del enlace */
  color: inherit; /* Hereda el color del texto del contenedor */
  display: block; /* Hace que el enlace ocupe el espacio del contenedor */
  width: 100%; /* Asegura que el enlace ocupe el ancho completo del contenedor */
  height: 100%; /* Asegura que el enlace ocupe la altura completa del contenedor */
}

.column-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.custom-link .column-content {
  display: flex; /* Mantiene el diseño flex del contenido */
  align-items: center;
  width: 100%;
  justify-content: center;
}


</style>