<template>
  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded" :class="`${customClass}`">
    <div class="row">
      <div class="col-lg-3">
        <img class="img-fluid" :src="getThumbnailService(itemService)"  :alt="itemService.additional_service_description_es">
      </div>
      <div class="col-lg-9">
        <h4 class="card-title text-dark" :title="itemService.additional_service_description_es">
          <strong>{{ (itemService.additional_service_description_es) }}</strong>
        </h4>
        <p>{{ itemService.additional_service_long_description_es }}</p>

        <hr>
        <button @click="addAddSerToCart(itemService)" class="btn btn-primary btn-lg me-3">
          <i class="bi bi-cart-plus"></i> {{ $t('buttons.add_now') }}
        </button>
        <button @click="openDetailsAddSer(itemService)" class="btn btn-light btn-lg me-3">
          <i class="bi bi-arrow-up-right"></i> {{ $t('buttons.view_more') }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AServiceCardLg',
  props: ['itemService', 'customClass', 'fromHome'],
  methods: {
    addAddSerToCart(itemService) {
      let itemsCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // salesQuoteInformation.flight_prices_sales_quote

      if(!itemsCart.flight_id) {
        alert('Por favor añada primero un vuelo en globo')
        return false
      }

      const lang = localStorage.getItem('language') || 'en';
      let checkoutUrl = `${lang}/checkout`
      Swal.fire({
        icon: 'success',
        title: 'Perfecto',
        html: `Hemos agregado <strong>${itemService.additional_service_description_es}</strong> a su carrito de compras. <a href="/${checkoutUrl}">Pagar ahora.</a>`,
        confirmButtonText: 'Cerrar'
      });

      // Obtener los elementos del localStorage
      let itemsAdditionalServicesCart = JSON.parse(localStorage.getItem('items-additional-services-cart')) || [];

      // Verificar si el item ya existe en el array
      const exists = itemsAdditionalServicesCart.some(service => parseInt(service.additional_service_id) === parseInt(itemService.additional_service_id));

      // Si no existe, añadirlo al array
      if (!exists) {
        itemService['quantities'] = 1
        itemsAdditionalServicesCart.push(itemService);

        // Guardar el array actualizado en el localStorage
        localStorage.setItem('items-additional-services-cart', JSON.stringify(itemsAdditionalServicesCart));
      } else {
        console.log('El servicio ya está en el carrito');
      }

      // Para depuración, puedes imprimir el contenido actualizado del localStorage
      console.log(localStorage.getItem('items-additional-services-cart'));
    },
    openDetailsAddSer(itemService) {
      this.$emit('service-details-opened', {
        is_opened: true,
        item_service: itemService
      })
    },
    getFormattedPrice(servicePrice) {
      let priceFormatted = this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',');

      return `$${priceFormatted} p/${servicePrice.unit_long_name_es}`
    },
    abbreviateText(text) {
      if(this.fromHome && this.fromHome !== false) {
        return text;
      }

      const maxLength = 10;
      if (text.length > maxLength) {
        return text.slice(0, maxLength).trim() + '...';
      }
      return text;
    },
    getThumbnailService(itemService) {
      if(!itemService.service_thumbnail) {
        return 'https://via.placeholder.com/500x500.png?text=Unavailable'
      }
      return itemService.service_thumbnail.path_image_medium
    }
  }
}
</script>

<style scoped>

</style>
