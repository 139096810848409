<template>
  <div class="container pt-5 pb-5">
    <h1 class="text-success border-bottom pb-5"><i class="bi bi-check-circle"></i> Su referencia está lista</h1>

    <p class="lead">A continuación, descargue la <strong>referencia para pago en tiendas de conveniencia</strong> dando click en el botón de descarga, una vez pague en la tienda de conveniencia de su preferencia, el comercio nos notificará su pago y confirmaremos su reserva en los medios de contacto que proporcionó.</p>
    <button type="button" @click="openDocumentUrl" class="btn btn-lg btn-dark mb-3"><i class="bi bi-download"></i> Descargar referencia</button>
    <p class="text-primary">Para cualquier duda, cambio o aclaración, conserve su folio de reserva</p>
    <p class="display-5"><strong>{{ salesQuoteId }}</strong></p>
  </div>
</template>

<script>
export default {
  name: 'DownloadStoreReference',
  props: ['salesQuoteId', 'referenceData'],
  methods: {
    openDocumentUrl() {
      if(this.referenceData.pdf_url) {
        window.open(this.referenceData.pdf_url, '_blank');
      }

    }
  }
}
</script>

<style scoped>
</style>