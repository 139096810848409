<template>
  <div>
    <div class="timeline">
      <div class="timeline-item" v-for="itemItinerary in itemFlight.flight_itinerary">
        <div class="timeline-dot"></div>
        <div class="timeline-content">
          <span class="timeline-time" v-html="convertTimeToHs(itemItinerary.hour_itinerary)"></span>
          <p><img class="icon-itinerary-to-black me-2" :src="itemItinerary.custom_img_icon" alt=""> {{ itemItinerary.description_es }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItineraryFlight',
  props: ['itemFlight'],
  methods: {
    convertTimeToHs(timeString) {
      if(timeString == '-') {
        return 'Sorpresa'
      }

      const [hours, minutes] = timeString.split(":");
      return `${hours}:${minutes} hs`;
    },
  }
}
</script>

<style scoped>
</style>