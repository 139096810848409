<template>
  <div>
    ...
  </div>
</template>

<script>
export default {
  name: 'QuoteFormFlight'
}
</script>

<style scoped>
</style>