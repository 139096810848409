<template>
  <div>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/tciw3U8B79M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'MeetTheExperience'
}
</script>

<style scoped>
</style>