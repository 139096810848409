<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="p-3 container-recommendations">
          <p class="text-center display-1 text-success"><i class="bi bi-check-circle-fill"></i></p>
          <p class="text-center"><strong>{{ recommendations.allowed.title }}</strong></p>
          <div class="row">
            <div class="col-lg-6" v-for="itemR in recommendations.allowed.items">
              <p>{{ itemR }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="p-3 container-recommendations">
          <p class="text-center display-1 text-danger"><i class="bi bi-slash-circle-fill"></i></p>
          <p class="text-center"><strong>{{ recommendations.not_allowed.title }}</strong></p>
          <div class="row">
            <div class="col-lg-6" v-for="itemR in recommendations.not_allowed.items">
              <p>{{ itemR }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Recommendations',
  data() {
    return {
      recommendations: {
        allowed: {
          title: 'El día de tu vuelo te recomendamos:',
          items: [
            'Revisar tu pase de abordar (pasajeros, hora de cita, fecha de vuelo, etc)',
            'Usar bloqueador solar, gorra, boina o sombrero',
            'Portar ropa y calzado cómodos (sudaderas, tenis, jeans, gorra, botas, pants)',
            'Usar tu cámara fotográfica y/o dispositivo móvil con la mayor precaución posible'
          ]
        },
        not_allowed: {
          title: 'Por tu seguridad es recomendable NO:',
          items: [
            'Asistir con faldas, vestidos, zapatos de tacón, sandalias, huaraches',
            'Asistir con cirugías recientes o problemas de columna y/o rodillas',
            'Portar mochilas, maletas, bolsas, back pack o cualquier objeto voluminoso',
            'Asistir en estado de ebriedad o intoxicado'
          ]
        }
      },
    }
  }
}
</script>

<style scoped>
</style>