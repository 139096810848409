<template>
  <div>
<!--    <slick :options="slickOptionsBanners">
      <div v-for="i in 4" :key="i" class="slider-item">
        <a href="#!">
          <div class="banner-image" :style="{ backgroundImage: `url('https://via.placeholder.com/1920x300.png?text=Imagen')` }">
            <button class="btn btn-lg btn-primary">Comprar</button>
          </div>
        </a>
      </div>
    </slick>-->

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 ">
          <div class="left-section-home">
            <LeftHomeView :external-hide-search-input="externalHideSearchInput"></LeftHomeView>
          </div>
        </div>
        <div class="col-lg-8 p-0 m-0 text-white">
          <div class="right-section-home p-0 m-0">
            <RightHomeView ></RightHomeView>
          </div>
        </div>
      </div>


      <div class="p-lg-5">
        <template v-if="flightsHome && flightsHome.length > 0">
          <div class="mb-5">
            <h2 class="pb-3 text-primary text-center mt-3 mb-3 "><i class="bi bi-chevron-double-right"></i> Conoce nuestros mejores vuelos en globo</h2>

            <div class="row  justify-content-center align-items-start">
              <div class="col-lg-3 col-md-12 d-flex justify-content-center align-items-start" v-for="itemFlight in flightsHome">
                <div class="card shadow-lg rounded border-0 me-lg-5 ">
                <span class="custom-home-bad-top-left badge rounded-pill" :style="{backgroundColor: itemFlight.highlighted_background_color_badge_flight_es}">
                  {{ itemFlight.highlighted_text_flight_es }}
                </span>
                  <img  :src="itemFlight.flight_thumbnail.path_image_medium" :alt="itemFlight.flight_name" class="img-fluid rounded-top">
                  <div class="card-body">
                    <h4 class="card-title text-primary">{{ itemFlight.flight_name }}</h4>
                    <div class="highlight-top-text mb-2">
                      <img class="img-fluid" :src="getHighlightLink(itemFlight)" alt=""> {{ itemFlight.highlighted_icon_text_flight_es }}
                    </div>
                    <div class="card-text">
                      <p class="m-0 p-0 fs-6">
                        <span class="text-danger"><s>${{ getBeforePrice(itemFlight) }}</s></span>
                        <span class="text-secondary">Ahorras: ${{ getSavingDifferencePrice(itemFlight) }}</span>
                      </p>
                      <h1 class="fw-bold">
                        ${{ getFrontendPrice(itemFlight.flight_after_discount_price) }} MXN
                      </h1>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button @click="addFlightToCart(itemFlight)" class="btn btn-primary btn-md btn-custom-action flex-fill mb-2 mb-md-0 me-md-2 w-100">
                      <i class="bi bi-arrow-right"></i> {{ $t('flights_section.buy_now') }}
                    </button>
                    <button @click="viewMore(itemFlight)" class="btn btn-light btn-md btn-custom-action flex-fill mb-2 mb-md-0 w-100">
                      <i class="bi bi-arrow-up-right"></i> {{ $t('flights_section.view_more') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </template>

        <div class="p-lg-5">
          <h2 class="pb-3 text-primary text-center mt-3 mb-3"><i class="bi bi-chevron-double-right"></i> {{ $t('home_section.additional_services_title') }}</h2>
          <div>
            <template v-if="additionalServices && additionalServices.length > 0">
              <VueSlickCarousel v-bind="slickOptionsServices" ref="slickserviceshome" class=" additional-services-carousel-home">
                <div v-for="service in additionalServices" :key="service.additional_service_id" class="px-2">
                  <AServiceCardHome @service-details-opened="openServiceDetailsModal" :item-service="service"
                                    :custom-class="'item-service-card'"/>
                </div>
              </VueSlickCarousel>
            </template>
          </div>

        </div>
      </div>

    </div>


    <!-- Modal -->
    <div class="modal fade" id="detailsAddSerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <template v-if="currentItemService">
              <h5 class="modal-title text-primary" id="exampleModalLabel">
                {{ currentItemService.additional_service_description_es }}</h5>
            </template>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <template v-if="currentItemService">
              <div class="row">
                <div class="col-lg-4 col-md-12">
                  <img :src="currentItemService.service_thumbnail.path_image_medium"
                       :alt="currentItemService.service_thumbnail.alt_image_text" class="img-fluid">
                </div>
                <div class="col-lg-8 col-md-12">
                  <h3 class="text-primary">{{ currentItemService.additional_service_description_es }}</h3>
                  <p class="text-dark">{{ currentItemService.additional_service_long_description_es }}</p>
                  <h5 class="pt-3 border-top text-dark" v-html="getFormattedPriceAddSer(currentItemService)"></h5>
                </div>
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <p class="text-dark"><small>* Algunos servicios adicionales podrían incrementar su costo si es que no los
              adquiere a través de este sitio web al momento de finalizar su compra. Esto es debido a los convenios y
              promociones que sólo podrá adquirir a través de este sitio web.</small></p>
            <button @click="closeModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            <button @click="goAdditionalServicesHomePage" type="button" class="btn btn-light"><i
                class="bi bi-arrow-up-right"></i> Ver más servicios adicionales
            </button>
            <button type="button" class="btn btn-primary"><i class="bi bi-cart-plus"></i> Añadir ahora</button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import RightHomeView from "@/views/templates/RightHomeView.vue";
import LeftHomeView from "@/views/templates/LeftHomeView.vue";
import AServiceCardHome from "@/views/templates/AServiceCardHome.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import AServiceCard from "@/views/templates/AServiceCard.vue";

export default {
  name: 'HomeView',

  components: {AServiceCardHome, LeftHomeView, RightHomeView, VueSlickCarousel},
  data() {
    return {
      flightsHome: [],
      externalHideSearchInput: false,
      currentItemService: null,
      slickOptionsBanners: {
        centerMode: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: true
      },
      slickOptionsFlightsHome: {
        infinite: false, // Desactivar el desplazamiento infinito
        slidesToShow: 3, // Para pantallas grandes
        slidesToScroll: 3, // No necesita scroll incremental, muestra todos los slides de golpe
        autoplay: false, // Desactivar el autoplay
        speed: 0, // Desactivar cualquier animación de transición
        dots: false, // Opcional: desactivar los puntos de navegación si no se requieren
        arrows: false, // Desactivar flechas si no se necesitan
        centerMode: true, // Mantén el modo centrado activado
        variableWidth: true, // Permite ancho dinámico en pantallas grandes
        centerPadding: '0px', // Ajusta el padding del centro
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2, // Dos elementos en pantallas medianas
              slidesToScroll: 1,
              variableWidth: true, // Mantener ancho dinámico en pantallas medianas
              centerMode: true,
              centerPadding: '40px',
            }
          },
          {
            breakpoint: 768, // Para móviles
            settings: {
              slidesToShow: 1, // Un solo elemento en móviles
              slidesToScroll: 1,
              variableWidth: false, // Desactivar el ancho dinámico
              centerMode: false, // Desactivar modo centrado en móviles
              centerPadding: '0px', // No padding adicional
              arrows: false, // Opcional: desactiva las flechas en móviles si es necesario
            }
          }
        ]
      },
      additionalServices: null,
      slickOptionsServices: {
        infinite: true,
        slidesToShow: 5, // Número de elementos en pantalla grande
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
        responsive: [
          {
            breakpoint: 1500, // Para pantallas menores a 1024px (tableta y móviles)
            settings: {
              slidesToShow: 3, // Número de elementos en pantallas de tableta
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1024, // Para pantallas menores a 1024px (tableta y móviles)
            settings: {
              slidesToShow: 3, // Número de elementos en pantallas de tableta
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768, // Para pantallas menores a 768px (móviles)
            settings: {
              slidesToShow: 1, // Número de elementos en móviles
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  mounted() {
    console.log('render-event executed')
    document.dispatchEvent(new Event('render-event'));
    this.getAdditionalServices()
    this.getHomeFlights()
  },
  methods: {
    getHighlightLink(itemFlight){
      let urlImage = `${process.env.VUE_APP_API_URL}${itemFlight.highlighted_icon_image_path_flight_es}`

      return urlImage
    },
    getBeforePrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_before_discount_price.flight_price, 0, '', ',');

      return priceFormatted
    },
    getSavingDifferencePrice(itemFlight){
      let difference = (parseFloat(itemFlight.flight_before_discount_price.flight_price) - parseFloat(itemFlight.flight_after_discount_price.flight_price)).toFixed(2)
      return this.$number_format(difference, 0, '', ',')
    },
    viewMore(flightSliderItem) {
      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'flight-details-' + lang,
        params: {
          slug: flightSliderItem.flight_slug,
          itemFlight: JSON.stringify(flightSliderItem)
        }
      });
    },
    addFlightToCart(itemFlight){
      // get localstorage
      let itemsCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // remove item flight if exists and replace to the new one
      //console.log(JSON.stringify(itemsCart))

      localStorage.setItem('items-cart', JSON.stringify(itemFlight))

      console.log(localStorage.getItem('items-cart'))

      const lang = localStorage.getItem('language') || 'en';
      let checkoutUrl = `${lang}/checkout`
      Swal.fire({
        icon: 'success',
        title: 'Perfecto',
        html: `Hemos agregado <strong>${itemFlight.flight_name}</strong> a su carrito de compras. <a href="/${checkoutUrl}">Pagar ahora.</a>`,
        confirmButtonText: 'Cerrar'
      });
    },
    getFrontendPrice(mainPrice) {
      return this.$number_format(mainPrice.flight_price, 0, '', ',');
    },
    closeModal() {
      this.externalHideSearchInput = false
    },
    getFormattedPriceAddSer(servicePrice) {
      let priceFormatted = this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <small>p/${servicePrice.unit_long_name_es}</small>`
    },
    openServiceDetailsModal(params = {}) {
      this.externalHideSearchInput = true
      console.log(params)
      if (params.is_opened) {
        this.currentItemService = params.item_service

        const modalElement = document.getElementById('detailsAddSerModal');
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }

    },
    getHomeFlights() {
      this.$http.get(`/manage-services/flights/get-flights?show-flight-in-home-view=1&show-flight-in-flights-view=0&order-flight-home-view=1`)
          .then((res) => {
            this.flightsHome = res.data.data
          })
    },
    goAdditionalServicesHomePage() {
      // Obtener el elemento del modal

      const modalElement = document.getElementById('detailsAddSerModal');

      // Verificar que el modalElement exista
      if (modalElement) {
        // Crear una instancia del modal de Bootstrap
        const modalInstance = bootstrap.Modal.getInstance(modalElement) || new bootstrap.Modal(modalElement);

        // Cerrar el modal
        modalInstance.hide();
      }

      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'services-' + lang,
      });
    },
    getAdditionalServices() {
      this.$http.get(`/manage-services/flights/get-additional-services?limit=5`)
          .then((res) => {
            this.additionalServices = res.data.data

          }).finally(() => {
      })
    },
  }
}
</script>
<style>
</style>
