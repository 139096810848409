<template>
  <div class="card shadow-sm rounded border-0">
    <img :alt="flightSliderItem.flight_name" :src="flightSliderItem.flight_thumbnail.path_image_medium"  class="img-fluid">
    <div class="card-body">
      <h4 class="card-title text-primary">{{ flightSliderItem.flight_name }}</h4>
      <div class="card-text ">
        <p class="m-0 p-0 fs-6"><span class="text-danger"><s>${{ getBeforePrice(flightSliderItem) }}</s></span> <span class="text-secondary">Ahorras: ${{ getSavingDifferencePrice(flightSliderItem) }}</span></p>
        <h4 class=" fw-normal">
          ${{getFrontendPrice(flightSliderItem.flight_after_discount_price)}} MXN
        </h4>
      </div>
    </div>
    <div class="card-footer">
      <button @click="addFlightToCart(flightSliderItem)" class="btn btn-primary btn-md btn-custom-action flex-fill mb-2 mb-md-0 me-md-2 w-100">
        <i class="bi bi-arrow-right"></i> {{ $t('flights_section.buy_now') }}
      </button>
      <button @click="viewMore(flightSliderItem)" class="btn btn-light btn-md btn-custom-action flex-fill mb-2 mb-md-0 w-100">
        <i class="bi bi-arrow-up-right"></i> {{ $t('flights_section.view_more') }}
      </button>

    </div>
  </div>
</template>

<script>
export default {
  name: 'FlightCard',
  props: ['flightSliderItem', 'customClass'],
  methods: {
    addFlightToCart(itemFlight){
      // get localstorage
      let itemsCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // remove item flight if exists and replace to the new one
      //console.log(JSON.stringify(itemsCart))

      localStorage.setItem('items-cart', JSON.stringify(itemFlight))

      console.log(localStorage.getItem('items-cart'))

      const lang = localStorage.getItem('language') || 'en';
      let checkoutUrl = `${lang}/checkout`
      Swal.fire({
        icon: 'success',
        title: 'Perfecto',
        html: `Hemos agregado <strong>${itemFlight.flight_name}</strong> a su carrito de compras. <a href="/${checkoutUrl}">Pagar ahora.</a>`,
        confirmButtonText: 'Cerrar'
      });
    },
    getBeforePrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_before_discount_price.flight_price, 0, '', ',');

      return priceFormatted
    },
    getSavingDifferencePrice(itemFlight){
      let difference = (parseFloat(itemFlight.flight_before_discount_price.flight_price) - parseFloat(itemFlight.flight_after_discount_price.flight_price)).toFixed(2)
      return this.$number_format(difference, 0, '', ',')
    },

    getFrontendPrice(mainPrice) {
      return this.$number_format(mainPrice.flight_price, 0, '', ',');
    },
    getFormattedPrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_price, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <small>p/${mainPrice.unit_name}</small>`
    },
    viewMore(flightSliderItem) {
      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'flight-details-' + lang,
        params: {
          slug: flightSliderItem.flight_slug,
          itemFlight: JSON.stringify(flightSliderItem)
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
