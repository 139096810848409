<template>
  <div class="shadow-sm p-3 mb-5 bg-light rounded">

    <div class="highlight-top-text mb-2">
      <img class="img-fluid" :src="getHighlightLink(itemFlight)" alt=""> {{ itemFlight.highlighted_icon_text_flight_es }}
    </div>
    <div class="d-flex align-items-top">
      <div class="flex-shrink-0">
        <img class="img-fluid rounded-bottom" :src="itemFlight.flight_thumbnail.path_image_small" :alt="itemFlight.flight_thumbnail.alt_image_text"/>
      </div>
      <div class="flex-grow-1 ms-3">
        <h5 class="m-0 p-0 text-primary fw-semibold" v-html="itemFlight.flight_name"></h5>


        <div class="mt-1">
          <i v-for="itemInclude in itemFlight.flight_includes"
             data-bs-toggle="tooltip"
             data-bs-placement="top"
             :title="itemInclude.additional_item_name_es"
             :class="[itemInclude.custom_web_icon, 'me-3', 'text-body-tertiary']"></i>
        </div>
<!--        <p class="m-0 p-0" v-html="itemFlight.flight_s_description"></p>-->

        <p class="m-0 pt-2 pb-2 h5 fw-medium">
          {{ `${getFrontendPrice(itemFlight.flight_after_discount_price)}` }} <small class="custom-small-size">/{{itemFlight.flight_after_discount_price.unit_name}}</small>
        </p>
        <span class="badge rounded-pill" :style="{backgroundColor: itemFlight.highlighted_background_color_badge_flight_es}">
          {{ itemFlight.highlighted_text_flight_es }}
        </span>

        <RaitingStars :fill="parseFloat(itemFlight.raiting_global_number)"></RaitingStars>

      </div>
    </div>

    <div class="border-top mt-3">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-column flex-md-row">
            <button @click="finishHomeFlightBook(itemFlight)" class="btn btn-primary btn-md btn-custom-action flex-fill mb-0 mb-md-0 me-md-2 w-100">
              <i class="bi bi-arrow-right"></i> Elegir y finalizar compra
            </button>
<!--            <button @click="viewMore(itemFlight)" class="btn btn-secondary btn-md btn-custom-action flex-fill mb-0 mb-md-0 w-100">
              <i class="bi bi-arrow-up-right"></i> {{ $t('flights_section.view_more') }}
            </button>-->
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import RaitingStars from "@/views/templates/RaitingStars.vue";

export default {
  name: 'SelectFlightItem',
  components: {RaitingStars},
  props: ['itemFlight'],
  methods: {
    getHighlightLink(itemFlight){
      let urlImage = `${process.env.VUE_APP_API_URL}${itemFlight.highlighted_icon_image_path_flight_es}`

      return urlImage
    },
    viewMore(itemFlight) {
      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'flight-details-' + lang,
        params: {
          slug: itemFlight.flight_slug,
          itemFlight: JSON.stringify(itemFlight)
        }
      });
    },
    viewDetailsFlight(){

    },
    getFrontendPrice(mainPrice) {
      return `$${this.$number_format(mainPrice.flight_price, 0, '', ',')}`;
    },
    getFormattedPrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_price, 0, '', ',');

      return `$${priceFormatted} <small>p/${mainPrice.unit_name}</small>`
    },
    finishHomeFlightBook(itemFlight){
      // get localstorage
      //let itemsCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // remove item flight if exists and replace to the new one
      //console.log(JSON.stringify(itemsCart))

      this.$emit('flight-selected', itemFlight)


      //let checkoutUrl = `${lang}/checkout`


      // redirect to checkout with flight date selected

      /*Swal.fire({
        icon: 'success',
        title: 'Perfecto',
        html: `Hemos agregado <strong>${itemFlight.flight_name}</strong> a su carrito de compras. <a href="/${checkoutUrl}">Pagar ahora.</a>`,
        confirmButtonText: 'Cerrar'
      });*/
    },
  }
}
</script>

<style scoped>
</style>