<template>
  <div>
    <div class="row">
      <div class="col-lg-6" v-for="itemPayment in paymentMethods">
        <div class="payment-method-container">
          <p class="text-black"><strong><i class="bi bi-chevron-double-right"></i> {{ itemPayment.title }}</strong></p>
          <div class="d-block">
            <div class="d-inline-block" v-for="itemMethod in itemPayment.methods">
              <img class="icon-small me-5" :src="itemMethod.img_icon" :alt="itemMethod.title" :title="itemMethod.title">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymenMethods',
  data() {
    return {
      paymentMethods: [
        {
          title: "Depósitos y transferencias bancarias",
          methods: [
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/visa.png", title: "Visa" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/mastercard.png", title: "Mastercard" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/amex.png", title: "Amex" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/carnet.png", title: "Carnet" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/discover.png", title: "Discover" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/spei.png", title: "SPEI" }
          ]
        },
        {
          title: "Efectivo en sucursales",
          methods: [
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/oxxo.png", title: "Oxxo" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/sams.png", title: "Sams Club" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/walmart.png", title: "Walmart" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/farmaciasdelahorro.png", title: "Farmacias Del Ahorro" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/7eleven.png", title: "7 Eleven" }
          ]
        },
        {
          title: "Pago en línea",
          methods: [
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/paypal.png", title: "Paypal" },
            { img_icon: "https://www.skyballoons.mx/assets/images/metodos-pago/openpay.png", title: "Openpay" }
          ]
        }
      ],
    }
  }
}
</script>

<style scoped>
</style>