<template>
  <div>
    <p>{{ salesQuoteId }}</p>
    <p>{{ invoicePaymentId }}</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorPayment'
}
</script>

<style scoped>
</style>