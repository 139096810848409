<template>
  <div class="card shadow-sm mb-4" :class="`${customClass}`">
    <img :src="getThumbnailService(itemService)"  :alt="itemService.alt_image_text">
    <div class="card-body">
      <p class="card-title text-dark" :title="itemService.additional_service_description_es">
        <strong>{{ abbreviateText(itemService.additional_service_description_es) }}</strong>
      </p>
      <p class="card-text text-success" v-html="getFormattedPrice(itemService)">
      </p>
      <button @click="addAddSerToCart(itemService)" class="btn btn-primary btn-sm d-block w-100">
        <i class="bi bi-cart-plus"></i> {{ $t('buttons.add_now') }}
      </button>
      <button @click="openDetailsAddSer(itemService)" class="btn btn-light btn-sm d-block w-100">
        <i class="bi bi-arrow-up-right"></i> {{ $t('buttons.view_more') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AServiceCard',
  props: ['itemService', 'customClass', 'fromHome'],
  methods: {
    addAddSerToCart(itemService) {
      // Obtener los elementos del localStorage
      let itemsAdditionalServicesCart = JSON.parse(localStorage.getItem('items-additional-services-cart')) || [];

      // Verificar si el item ya existe en el array
      const exists = itemsAdditionalServicesCart.some(service => parseInt(service.additional_service_id) === parseInt(itemService.additional_service_id));

      // Si no existe, añadirlo al array
      if (!exists) {
        itemService['quantities'] = 1
        itemsAdditionalServicesCart.push(itemService);

        // Guardar el array actualizado en el localStorage
        localStorage.setItem('items-additional-services-cart', JSON.stringify(itemsAdditionalServicesCart));
      } else {
        console.log('El servicio ya está en el carrito');
      }

      // Para depuración, puedes imprimir el contenido actualizado del localStorage
      console.log(localStorage.getItem('items-additional-services-cart'));
    },
    openDetailsAddSer(itemService) {
      this.$emit('service-details-opened', {
        is_opened: true,
        item_service: itemService
      })
    },
    getFormattedPrice(servicePrice) {
      let priceFormatted = this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <br><small>p/${servicePrice.unit_long_name_es}</small>`
    },
    abbreviateText(text) {
      if(this.fromHome && this.fromHome !== false) {
        return text;
      }

      const maxLength = 10;
      if (text.length > maxLength) {
        return text.slice(0, maxLength).trim() + '...';
      }
      return text;
    },
    getThumbnailService(itemService) {
      if(!itemService.service_thumbnail) {
        return 'https://via.placeholder.com/50x50.png?text=Unavailable'
      }
      return itemService.service_thumbnail.path_image_small
    }
  }
}
</script>

<style scoped>

</style>
